<template lang="html">
  
  <div class="">
    <div class="">
      <div class="">
<ul class="nav nav-tabs" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true" @click="mostrarFavoritos">Crédito</button>
  </li>
  <li class="nav-item" role="presentation">
    <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false" @click="mostrarFavoritos">Opciones de crédito</button>
  </li>
  <!--<li class="nav-item" role="presentation">
    <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false" @click="mostrarFavoritos">Productos</button>
  </li>-->
  <li class="nav-item sm:ml-auto fav-btn-wrp" role="presentation" v-if="muestraFavoritos"> 
    <button class="nav-fav sm:ml-4" id="fav-tab" data-bs-toggle="tab" data-bs-target="#fav" type="button" role="tab" aria-controls="fav" aria-selected="false">
              
              <div class="vx-col" v-if="caso.favoritos.length>0">
                <span class=" cursor-pointer" @click="openFavs()" >Ver Favoritos <span class="font-medium"> ({{caso.favoritos.length}})</span></span>
                
              </div>
              <div class="vx-col" v-if="caso.favoritos.length==0">
                <span class=" cursor-pointer"  >Ver Favoritos <span class="font-medium"> ({{caso.favoritos.length}})</span></span>
                
              </div> 
              
    </button>
  </li>
</ul>
<div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
        <vx-card :title="'Folio '+ id"  class="tab-body folio-tab-header">
           <div id="datosCotizacion">
            <div class="vx-row vs-con-loading__container" v-if="items.length">
              <!--<div class="vx-col w-full">
                <span class="font-medium text-primary cursor-pointer" @click="openFavs()" v-if="caso.favoritos.length">Ver Favoritos ({{caso.favoritos.length}})</span>
                <br>

              </div>-->

            </div>
          </div>
          <div class="">
          <div class="" >
            <div class="row w-full">
              <div class="col-xxl-8">
                <span class="span-placeholder" for="destinos">Destino</span>
                <select name="cars" id="destinos" v-model="destino" class="vs-input">
                  <option :value='tipo.Id' v-for="tipo in destinos"  :key="">{{tipo.Nombre}}</option>
                </select>
                <!--<vs-radio :value='tipo.Id' v-model="destino" v-for="tipo in destinos"  :key="" class="mr-4" >{{tipo.Nombre}}</vs-radio>-->

              </div>
              <!--<div class="col-md-4" v-if="destino == 1 || destino == 5 || destino == 2 || destino == 20">
                <span class="span-placeholder" for="Programas">Programas</span>
                <select name="cars" id="Programas" v-model="caso.programa" class="vs-input" @change="cambia()">
                  <option :value='tipo.Id' v-for="tipo in programas"  :key="">{{tipo.Nombre}}</option>
                </select>

              </div>-->
            </div>
            <!--<AdquisicionCofi :programa="caso.programa" :otro="otro" v-if="destino == 1 && (caso.programa == 4 || caso.programa == 9)" v-on:updateValores="update($event)"/>-->
            <!--<Adquisicion :otro="otro" v-if="(destino == 1 || destino == 24 || destino == 21 || destino == 3 || destino == 27 || destino == 15 || destino == 8 || destino == 10 || destino == 32 || destino == 37) && (caso.programa==1 || caso.programa==6)" v-on:updateValores="update($event)"/>-->
            <!--<Mejora :otro="otro" v-if=" destino == 25 || destino == 22 || destino == 19 ||  destino == 33 || destino == 34 " v-on:updateValores="update($event)"/>-->
             <!--<TerrenoProgramas :programa="caso.programa" :otro="otro" v-if="(destino == 5 || destino == 20 || destino == 2)&& (caso.programa == 4 || caso.programa == 9 )" v-on:updateValores="update($event)"/>-->
            <!--<Terreno :destino="destino" :otro="otro" v-if="(destino == 5 || destino == 20 || destino == 2 || destino == 37)&& (caso.programa==1 || caso.programa == 6)" v-on:updateValores="update($event)"/>-->
            <div class="">
              <div class="row w-full">
                <div class="col-sm-6 col-xxl-4">
                  <span class="span-placeholder">Valor de vivienda</span>
                  <div class="inputwrapper input-has-extra" data-required="MXM">
                      <input  id="demoTextBox"  class="inputDataText input-value" placeholder="$X,XXX,XXX.XX" v-validate="'max:11', 'min:6'"  v-model="caso.valorInmueble" name="vv" v-on:keypress="isNumber($event)" @change="calculaPrcentajeCredito()"/>
                  </div>
                  <span class="text-danger span-placeholder" v-show="validacionValorInm">El valor debe ser mayor a $225000.00 </span>
                </div>
                <div class="col-sm-6 col-xxl-4 mt-5 sm:mt-0">
                  <span class="span-placeholder">Monto de crédito</span>
                  <div class="alinear input-has-extra" >
                    <input id="demoTextBox"  class="inputDataText input-amount" placeholder="$X,XXX,XXX.XX" v-validate="'max:13'" label="Monto de crédito" v-model="caso.montoSolicitado" name="credito"   v-on:keypress="isNumber($event)" @change="calculaPrcentajeCredito()"/>
                    <div class="porcentaje">{{caso.porcentajeCredito}}</div>
                    <!--<span class="text-danger span-placeholder" v-show="errors.has('credito')">Máximo son 10 digitos</span>-->
                  </div>          
                  <span class="text-danger span-placeholder" v-show="validacion">El valor debe ser mayor a $225000.00 </span>

                </div>
              </div>
              <div class="row w-full" v-if="destino==19">
                <div class="col-sm-6 col-xxl-4">
                  <span class="span-placeholder">Pago mensual</span>
                  <div class="inputwrapper" data-required="MXM">        
                  <input id="demoTextBox"  class="inputDataText" placeholder="$X,XXX,XXX.XX" v-model="caso.pagoMensualActual" name="username" @change="updateValores()" v-on:keypress="isNumber($event)"/>
                  </div>
                </div>
                <div class="col-sm-6 col-xxl-4 mt-5 sm:mt-0">
                  <span class="span-placeholder" for="plazos">Tiempo restante crédito</span>
                  <select name="cars" id="plazos" v-model="caso.plazoRestante" class="vs-input" style="border: 1px solid rgba(0, 0, 0, 0.2);" @change="updateValores()">
                    <option :value='tipo.value' v-for="tipo in plazosRestantes"  :key="">{{tipo.label}}</option>
                  </select>
                </div>
              </div>

              <div class="row w-full">
                <div class="col-sm-6 col-xxl-4">
                  <span class="span-placeholder">Plazos a cotizar</span>
                  <v-select v-model="caso.plazosACotizar" multiple :closeOnSelect="false" :options="plazos" :dir="$vs.rtl ? 'rtl' : 'ltr'"  />
                </div>

                <div class="col-sm-6 col-xxl-4 mt-5 sm:mt-0">
                  <span class="span-placeholder">Esquemas de pago</span>
                  <v-select v-model="caso.esquemasPago" multiple :closeOnSelect="false" :options="esquemas" :dir="$vs.rtl ? 'rtl' : 'ltr'"  />
                </div>
                <!--<div class="vx-col lg:w-1/4 sm:w-full">
                    <span class="span-placeholder" for="gastosNotariales">Gastos notariales</span>
                      <select name="gastosNotariales" id="gastosNotariales" v-model="valores.gastosNotarial" class="v-select vs--single  vs-input--input" @change="updateValores()">
                        <option :value='tipo.value' v-for="tipo in gastosNotariales"  :key="">{{tipo.label}}</option>
                      </select>
                </div>-->
              </div>
              <input class="vs-input" hidden   v-model="otro" name="otro" @change="updateValores()" />
            </div>
            <div class="row w-full">
              <div class="col-xxl-8">
                <span class="span-placeholder">Bancos</span>
                <v-select v-model="caso.bancos" multiple :closeOnSelect="false" :options="bancos" :dir="$vs.rtl ? 'rtl' : 'ltr'"/>
              </div>
            </div>
            <div class="row w-full mt-4 save-credit-btn-wrp">
              <div class="col-xxl-8">
                <button class="btn btn-second" @click="updateCaso()">Guardar y cotizar crédito</button>
              </div>
            </div>
          </div>

        </div>
        </vx-card>    
  </div>
  <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
    <div class="tab-body">
          <div id="cotizacion">
            <div class="vx-row vs-con-loading__container" v-if="items.length">
              <!--<div class="vx-col w-full">
                <span class="font-medium text-primary cursor-pointer" @click="openFavs()" v-if="caso.favoritos.length">Ver Favoritos ({{caso.favoritos.length}})</span>
                <br>

              </div>-->

            </div>
          </div>
          <div class="table-credit-options">
              <table style="background-color:#F1F4FF; border-radius:5px;" class="table-credit-options-header">
              <tr  style=" background-color:#F1F4FF;  border-radius: 5px;">
                <td class="ocultar-celda col-md-2 td-modal2">
                  Banco
                </td>
                <td class="ocultar-celda col-md-3 td-modal2">Descripción</td>
                <td class="ocultar-celda col-md-1 td-modal"></td>
                <td class="col-md-2 td-modal2">
                  Ordenar por
                  <div class="">

                  <div class="" id="title"></div>
                  <div class="" v-if="destino == 19 || destino == 25 || destino == 22 || destino == 33  || destino == 34">
                    <select v-model="filtroModel"  @change="filtrar()" class="vs-ordenar">
                      <option :value="filtro.value"  v-for="filtro in filtrosComplet"  :key="filtro.value">{{filtro.label}}</option>
                    </select>
                  </div>
                  <div class="" v-else>
                    <select v-model="filtroModel"   @change="filtrar()" class="vs-ordenar">
                      <option :value="filtro.value" v-for="filtro in filtros"  :key="filtro.value" >{{filtro.label}}</option>
                    </select>
                  </div>
                </div>
                </td>
                
                <td class="ocultar-celda col-md-2" style="font-weight:600;">Acciones</td>
                <!--<td class="col-md-1 td-modal">Promociones</td>-->
                
                <td class="ocultar-celda col-md-1 td-modal">Promociones</td>
                <td class="ocultar-celda col-md-1 td-modal">Producto</td>
              </tr>
              </table>
                <div class="" v-for="(item, index) in items" :key="item.objectID"  style="">
                    <Productos :item="item" :filtroName="filtroName" :filtroId="filtroId" class="" :user="caso.solicitante" :ingreso="ingreso">
                        <template slot="mejora" v-if="(destino == 19 ) && item.MejoraMensualidad">
                          <tr><td class="td-modal">Ahorro Mensual:</td><td> ${{numberFormat(item.MejoraMensualidad)}}</td>
                        </tr>
                          <tr><td class="td-modal">Ahorro Total:</td><td> ${{numberFormat(item.AhorroTotal > 0 ? item.AhorroTotal : 0)}}</td></tr>
                        </template>
                        <!--<template slot="cofinavit" v-if="destino == 1 && caso.programa == 4 && item.GastosInicialesCofinavit">
                          <td class="td-modal">Gastos Iniciales con Cofinanciamiento Infonavit:</td> <td>${{ numberFormat(item.GastosInicialesCofinavit) }}</td>
                        </template>-->
                        <template slot="gastos" v-else>
                          <td class="td-modal">Gastos Iniciales:</td><td> ${{ numberFormat(item.GastosIniciales) }}</td>
                        </template>
                        <!-- SLOT: ACTION BUTTONS -->
                        <template slot="action-buttons">
                          <td class="col-md-1 link-view-product-wrp">
                            <div class="item-view-secondary-action-btn rounded-lg flex flex-grow items-center justify-center text-white cursor-pointer">
                              <button class="favoritos"  @click="solicitar(item)"><u>Visualizar producto</u></button> 
                            </div>
                            </td>
                        </template>                       
                        <template slot="action-buttons-2">
                        <div class="">  
                          <div class="col-md-6 icono-fav-wrp">
                          <vx-tooltip text="Favoritos">

                            <button class="favoritos" color="primary" type="line" @click.prevent='agregarFavorito(item)' v-if="comprobarFavorito(item)">
                                <svg class="heart" :svgClasses="[{'fill-current' : !comprobarFavorito(item)}]" viewBox="0 0 32 32" width="32" height="32">
                                  <path id="Vector" d="M19.855,0A7.63,7.63,0,0,0,13.75,3.066,7.634,7.634,0,0,0,0,7.686a14.683,14.683,0,0,0,.715,4.551C2.887,19.112,9.584,23.224,12.9,24.351a3,3,0,0,0,1.7,0c3.314-1.128,10.01-5.239,12.182-12.114A14.683,14.683,0,0,0,27.5,7.686,7.664,7.664,0,0,0,19.855,0Z" transform="translate(2.75 4.263)"/>
                                </svg>
                              </button>
                           <button class="favoritos" icon-pack="feather" @click.prevent='quitarFavorito(item)' v-else><feather-icon icon="HeartIcon" :svgClasses="[{'text-danger fill-current' : !comprobarFavorito(item)}, 'h-8 w-8']" /></button>


                              <!--
                            <button class="favoritos" color="primary" type="line" icon-pack="feather" @click.prevent='quitarFavorito(item)' v-else>
                                <svg class="heart" :svgClasses="[{'fill-current' : !comprobarFavorito(item)}]"  >
                                  <path id="Vector" d="M19.855,0A7.63,7.63,0,0,0,13.75,3.066,7.634,7.634,0,0,0,0,7.686a14.683,14.683,0,0,0,.715,4.551C2.887,19.112,9.584,23.224,12.9,24.351a3,3,0,0,0,1.7,0c3.314-1.128,10.01-5.239,12.182-12.114A14.683,14.683,0,0,0,27.5,7.686,7.664,7.664,0,0,0,19.855,0Z" transform="translate(2.75 4.263)"/>
                                </svg>
                            </button>-->
                            <!--
                            <button class="favoritos"  @click.prevent='agregarFavorito(item)' v-if="comprobarFavorito(item)"><feather-icon icon="HeartIcon" :svgClasses="[{'fill-current' : !comprobarFavorito(item)}]" /></button>
                            <button class="favoritos"  @click.prevent='quitarFavorito(item)' v-else><feather-icon icon="HeartIcon" :svgClasses="[{'text-danger fill-current' : !comprobarFavorito(item)}]" /></button>
                            -->
                          </vx-tooltip>
                          </div>
                         </div> 
                        </template>
                    </Productos>
                   
                </div>
                
              </div>
            
            </div> 
              
  </div>
  
  <!--<div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
    <div class="pro-card" >
      
         <div  > 
            <div class="tab-body-2" :dir="$vs.rtl ? 'rtl' : 'ltr'" :key="$vs.rtl" v-if="caso.solicitudes.length">
                  <!--<vx-tooltip text="Solicitud de crédito" >
      <vs-button color="primary" icon="icon-file" @click="generaSolicitud(item)">
        solicitud
      </vs-button>
    </vx-tooltip>-->
                    <!--<div class="col-md-4" v-for="item in caso.solicitudes" :key="item.Id">
                      <Solicitados :solicitados="item">
                        <template slot="action-favs">
                          <div class=" trash-pro">
                            <button type="filled" class="favoritos" @click='quitar(item)'>
                              <svg class="trash">
                                <g id="trash">
                                  <path id="Vector" d="M18,.5C14.67.17,11.32,0,7.98,0A59.068,59.068,0,0,0,2.04.3L0,.5" transform="translate(3 5.48)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                  <path id="Vector-2" data-name="Vector" d="M0,2.97.22,1.66C.38.71.5,0,2.19,0H4.81C6.5,0,6.63.75,6.78,1.67L7,2.97" transform="translate(8.5 2)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.34"/>
                                  <path id="Vector-3" data-name="Vector" d="M13.7,0l-.65,10.07c-.11,1.57-.2,2.79-2.99,2.79H3.64C.85,12.86.76,11.64.65,10.07L0,0" transform="translate(5.15 9.14)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                  <path id="Vector-4" data-name="Vector" d="M0,0H3.33" transform="translate(10.33 16.5)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.34"/>
                                  <path id="Vector-5" data-name="Vector" d="M0,0H5" transform="translate(9.5 12.5)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.34"/>
                                  <path id="Vector-6" data-name="Vector" d="M0,0H24V24H0Z" fill="none" opacity="0"/>
                                </g>
                              </svg>
                            </button>
                          </div>
                        </template>
                      </Solicitados>                    
                    </div>-->
                    <!--<div class="swiper-pagination" slot="pagination"></div>-->
                
            <!--</div>
            <div v-else>
              <span class="span-placeholder">No hay productos elegidos</span>
            </div>
          </div>  
    </div>  
  </div>-->
 </div>
 
</div>        


<!--termina el acordeom-->
    </div>
    <div  class="modal fade modal-inmob-resumen" id="modalTabla"  ref="table" size="xl" hide-footer hide-header>
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body modal-productos">
            <div class="vx-row justify-end modal-inmob-resumen-btns-wrp" justify="flex-end">
                  <vs-button class="verdebtn" color="success" size="small" @click="sendCorreoElegido()">Enviar a correo</vs-button>
                  
                    <vs-button class="azulbtn" size="small" @click="descargarPDF()" style="margin-left: 10px;">Descargar PDF</vs-button>
                </div>
                <input class="vs-input hidden" hidden  v-model="s"  />
                <input class="vs-input hidden" hidden  v-model="o"  />
                <br><br>
          <div class="row">
            <div class="col-md-8 mb-8 modal-inmob-resumen-logo">
              <div class="d-md-flex justify-content-md-left"><img src="https://cotizador.socasesores.com/uploads/logo_soc.png" alt="logo-soc"  style="margin: .5rem; height: 3.6rem; cursor:pointer;"/></div>
            </div>
            <div class="col-md-4">
										<b class="descripcion-producto  " style="font-size: 20px;"> <br> <strong>Comparador SOC </strong></b>
									</div>
            <div class="row">
              <div class="d-md-flex">
                <h6 class="descripcion-producto"  style="font-size: 15px; font-weight: bold;">Hola {{Solicitante.Nombres}}</h6>
              </div>
            </div>  
          </div>
            <div class="row">
              <p class="descripcion-producto">Tu asesor inmobiliario <span style="font-weight:bold;">{{activeUserInfo.Nombre}}</span>, realizo una simulación con las siguientes condiciones:</p>
           
              <ul class="ul-list">
                <li class="descripcion-producto">Valor de la vivienda: ${{caso.valorInmueble}}</li>
                <li class="descripcion-producto">Monto de crédito: ${{caso.montoSolicitado}}</li>
                <li class="descripcion-producto">Enganche: ${{enganche}}</li>
              </ul>
           
              <p class="descripcion-producto">Trabajamos de la mano de un asesor hipotecario <span style="font-weight:bold;"> {{brokerSupervisor}} </span> quien tiene alianza con las principales instituciones financieras de México, esto nos permite ayudarte a encontrar la mejor opción crediticia de acuerdo a tu perfil y necesidades.</p>
              <p class="d-md-flex justify-content-md-center"><img src="https://cotizador.socasesores.com/uploads/bannerFinancierasInmobiliario.png" class="banner-bancos w-full mt-2 pt-0"></p>
              <p class="descripcion-producto">Conoce un estimado de las condiciones de tu crédito</p>
            
              <ul class="ul-list">
                <li class="descripcion-producto">Banco seleccionado: {{bancoSeleccionadp}}</li>
                <li class="descripcion-producto">Mensualidad promedio:${{mensualidadPromedio}}</li>
                <li class="descripcion-producto">Tasa estimada: {{tasa}}%</li>
                <li class="descripcion-producto">Gastos notariales promedio: ${{gastosNotariales}}</li>
                <li class="descripcion-producto">Ingreso mínimo: ${{ingresoMinimo}}</li>
                <li class="descripcion-producto">CAT promedio: {{catPromedio}}%</li>
              </ul>
            
              <p class="descripcion-producto">¿Te gustaría recibir asesoría de un experto? Te acompañamos desde la elección de tu crédito hasta la firma de tus escrituras. <span style="font-weight:bold;">¡Nuestro servicio no tiene costo!</span></p>

              <p class="descripcion-producto" style="font-weight:bold; text-align: center;">Juntos, lo hacemos real.</p>
            
              <p class="descripcion-producto"><span style="font-weight:bold;">*Importante:</span> Las condiciones presentadas son <span style="font-weight:bold;">informativas</span> y <span style="font-weight:bold;">referenciales</span>, por lo que, éstas prodrían cambiar de acuerdo con el análisis de tu perfil y los parametros del banco.</p>
              
            </div>
            <div class="d-md-flex justify-content-md-center" id="icono"></div>
            <div class="row">
              <div class="d-md-flex justify-content-md-center"><h1 style="color: #00368C;"><img src="https://cotizador.socasesores.com/uploads/cintillo_Logo_Soc.png" alt="" width="100%" class="w-full">										</h1></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    
   
</template>

<script>
import vSelect from 'vue-select'
import Productos from '@/views/CasoInmobiliaria/ProductosInmobiliaria.vue'
import Favoritos from '@/views/CasoInmobiliaria/Favoritos.vue'
import Solicitados from '@/views/Caso/Solicitados.vue'
import 'swiper/dist/css/swiper.min.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import Adquisicion from '@/views/DestinosInmobiliaria/Adquisicion.vue'
import Mejora from '@/views/DestinosInmobiliaria/Mejora.vue'
import AdquisicionCofi from '@/views/DestinosInmobiliaria/AdquisicionCofi.vue'
import AdquisicionAliados from '@/views/DestinosInmobiliaria/AdquisicionAliados.vue'
import Terreno from '@/views/DestinosInmobiliaria/Terreno.vue'
import TerrenoConstruccion from '@/views/DestinosInmobiliaria/TerrenoConstruccion.vue'
//import TerrenoProgramas from '@/views/DestinosInmobiliaria/TerrenoProgrmas.vue'
export default {
  components: {
    
    vSelect,
    Productos,
    Favoritos,
    swiper,
    swiperSlide,
    Adquisicion,
    Mejora,
    AdquisicionCofi,
    AdquisicionAliados,
    Terreno,
    TerrenoConstruccion,
    Solicitados,
  },
  /*props: {
     validaVaciosSantanderExpress: {
      type: Boolean,
      required: false
    },
  },  */
  data(){
		return{
      swiperOption: {
        pagination: {
          el: '.swiper-pagination'
        }
      },
      socData:'',
      urlSolicitud:'',
      popupSolicitud:false,
      items:[],
      key:'',
			estatus:[],
			destinos:[],
      programas:[],
      id:'',
      caso:{
        porcentajeCredito:'70',
        valorInmueble:'',
        saldoInsoluto:'',
        plazos:[],
        plazosACotizar:[],
        destino:'',
        esquemas:[],
        esquemasPago:[],
        pagoMensualActual:'',
        plazoRestante:'',
        ejecutivo:'',
        estado:'',
        estatus:'',
        favoritos:[],
        solicitudes:[],
        montoSolicitado:'',
        programa:'',
        email:'',
        bancos:[],
        inmuebleNuevo:true,
        estado:'',
        plaza:'',
        solicitante:'',
        tasa:'',
        ssv:'',
        creditoInf:'',
        //gastosNotarial:3,
        valorProyecto:0,
        valorTerreno:0,
        montoCredito:0,
      },
      esquemas:[
        { label: 'Pago Fijo',  value: 2 },
        { label: 'Pago creciente',  value: 1 },
      ],
      plazos:[
        { label: '20 Años',  value: 20 },
        { label: '15 Años',  value: 15 },
        { label: '10 Años',  value: 10 },
        { label: '5 Años',  value: 5 },
        /*{ label: '19 Años',  value: '19' },
        { label: '18 Años',  value: '18' },
        { label: '17 Años',  value: '17' },
        { label: '16 Años',  value: '16' },
        { label: '14 Años',  value: '14' },
        { label: '13 Años',  value: '13' },
        { label: '12 Años',  value: '12' },
        { label: '11 Años',  value: '11' },
        { label: '9 Años',  value: '9' },
        { label: '8 Años',  value: '8' },
        { label: '7 Años',  value: '7' },
        { label: '6 Años',  value: '6' },
        { label: '4 Años',  value: '4' },
        { label: '3 Años',  value: '3' },
        { label: '2 Años',  value: '2' },
        { label: '1 Años',  value: '1' },*/
      ],
      plazosRestantes:[
        { label: '20 Años',  value: '20' },
        { label: '19 Años',  value: '19' },
        { label: '18 Años',  value: '18' },
        { label: '17 Años',  value: '17' },
        { label: '16 Años',  value: '16' },
        { label: '15 Años',  value: '15' },
        { label: '14 Años',  value: '14' },
        { label: '13 Años',  value: '13' },
        { label: '12 Años',  value: '12' },
        { label: '11 Años',  value: '11' },
        { label: '10 Años',  value: '10' },
        { label: '9 Años',  value: '9' },
        { label: '8 Años',  value: '8' },
        { label: '7 Años',  value: '7' },
        { label: '6 Años',  value: '6' },
        { label: '5 Años',  value: '5' },
        { label: '4 Años',  value: '4' },
        { label: '3 Años',  value: '3' },
        { label: '2 Años',  value: '2' },
        { label: '1 Años',  value: '1' },
      ],
      ejecutivos:[],
      bancosEjecutivo:[],
      bancos:[],
      filtroModel:1,
      filtros:[
        { label: 'Mensualidad',  value: '1' },
        { label: 'Cat',  value: '2' },
        { label: 'Tasa',  value: '3' },
        { label: 'Pago total',  value: '4' },
        { label: 'Con promociones',  value: '5' },
        { label: 'Ingreso Mínimo',  value: '6' },
      ],
      filtrosComplet:[
        { label: 'Mensualidad',  value: '1' },
        { label: 'Cat',  value: '2' },
        { label: 'Tasa',  value: '3' },
        { label: 'Pago total',  value: '4' },
        { label: 'Con promociones',  value: '5' },
        { label: 'Ingreso Mínimo',  value: '6' },
        /*{ label: 'Mayor ahorro total',  value: '7' },
        { label: 'Mayor ahorro mensual',  value: '8' },*/
      ],
      estados:[],
      plazas:[],
      tasas:[],
      destino:'',
      filtroName:'',
      filtroId:'',
      ingreso:{},
      otro:0,
      muestraFavoritos:false,
      itemsIons:[],
       ValorInmuebleKredi:'',
			EngancheKredi:'',
			PorcentajeEngancheKredi:'',
			PlazosKredi:'',
			FinanciamientoKredi:'',
			ComisionAperturaKredi:'',
		    EstadoKredi:'',
			GastosNotarialesKredi:'',
      Solicitante: '',
      s: '',
      o: '',
      linaje: '',     
      brokerSupervisor: '',
		}
  },
	mounted(){
    //alert(localStorage.getItem('validaCamposVacios'))
     if(localStorage.getItem('regresoImprimirFavs')){
      // alert('cambio')
       let elemento = document.getElementById('home-tab')
       elemento.classList.remove('active');
       let elementoTabCont=document.getElementById('home')
       elementoTabCont.classList.remove('show')
       elementoTabCont.classList.remove('active')
       elemento.setAttribute('aria-selected',"false")

       let elemento2=document.getElementById('profile-tab')
       elemento2.setAttribute('aria-selected',"true")
        elemento2.classList.add('active');
        let elementoTabCont2=document.getElementById('profile')
        elementoTabCont2.classList.add("show")
        elementoTabCont2.classList.add("active")
        this.muestraFavoritos=true
       setTimeout(() => localStorage.removeItem('regresoImprimirFavs'), 5000);
    }
    /*this.$vs.loading({
        container: '#cotizacion',
        scale: 0.6
      })*/
    this.id=this.$route.params.id
    this.socData=localStorage.getItem('socData')
    this.getApikey()
    this.getDestinos()
		this.getEstatus()
    //this.getProgramas()
    this.getBancos()
    this.listaSupervisados()
    this.getEstados()
    this.getTasas()
    this.dameCaso()
    this.cotiza()
    this.dameSupervisor()
    localStorage.removeItem('vistaNuevaCotizacion')
  },
  watch: {
    valorInm: function(newValue) {
      this.caso.valorInmueble= newValue.replace(/\D/g, "").replace(/([0-9])([0-9]{2})$/, '$1.$2').replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",");
      //this.montoInmAforo = (Math.round(newValue.replace(/,/g,"") * 0.90)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") //aforo 90%
    },
    valorCredito: function(newValue) {
      this.caso.montoSolicitado= newValue.replace(/\D/g, "").replace(/([0-9])([0-9]{2})$/, '$1.$2').replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",");
      //this.montoInmAforo = (Math.round(newValue.replace(/,/g,"") * 0.90)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") //aforo 90%
    },
  },
  computed:{
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    validacionValorInm() {
      return this.caso.valorInmueble.toString().replace(/,/g,"") < 250000
    },
    validacion() {
      return this.caso.montoSolicitado.toString().replace(/,/g,"") < 250000
    },
    valorCredito(){
      return this.caso.montoSolicitado
    },
    valorInm(){
      return this.caso.valorInmueble
    },
    destinoNombreSolo:function(){
      for (var i = 0; i < this.destinos.length; i++) {
        if (this.destinos[i].Id==this.destino) {
          return this.destinos[i].Nombre
        }
      }
    },
  },
	methods:{
     dameSupervisor(){      

      let self = this 
      let objRequestDameSupervisor = {
        strApiKey: this.key,
        strMetodo: 'dameSupervisorHipotecario',
        objBroker: {
          Linaje: this.linaje
        }
      }
      this.$axios.post( '/', objRequestDameSupervisor, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        }
      })
      .then(response => {
            if(response.data.intEstatus == 0) {
              this.brokerSupervisor = response.data.objContenido              

            } else {
              this.$vs.notify({
                title:'Ocurrio un error en DameSupervisor',
                text:response.data.strError,
                color:'danger',
                position:'top-right'
              });
        }
          })
          .catch(function (error) {
            self.$vs.notify({
              title:'Ocurrio un error de sistema',
              text:error,
              color:'danger',
              position:'top-right'
        })
      })
    },
    calculaPrcentajeCredito(){
      this.caso.porcentajeCredito= (this.caso.montoSolicitado.toString().replace(/,/g,"") / this.caso.valorInmueble.toString().replace(/,/g,"")) * 100
      this.caso.porcentajeCredito=this.caso.porcentajeCredito.toFixed(2)+'%'
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    limpiarBancos(){
      this.caso.bancos=[]
    },
    seleccionarTodos(){
      this.caso.bancos=this.bancos
    }, 
   pruebaIon(plazo, destinoId){
     /* product i.	Adquisición de vivienda = REAL_STATE
      ii.	Liquidación = LIQUIDITY
      iii.	Oficinas y locales = OFFICES_AND_STORES
      iv.	Adquisición de terreno = LAND
      */
     let self= this
     let destino=''
     let GastosInicialCalculado= ''
     let NombreProducto=''
     let amount =''
     let respuestaData=''
     let calculoAforo=''

     calculoAforo= (this.caso.montoSolicitado.replace(/,/g,"")*100)/this.caso.valorInmueble.replace(/,/g,"")
   
      switch(destinoId) {
        case 1:
          destino='REAL_ESTATE'
          GastosInicialCalculado= this.caso.valorInmueble.replace(/,/g,"")*0.08
          NombreProducto='ION Adquisición Plus'
          break;
        case 3:
          destino= 'LIQUIDITY'
          GastosInicialCalculado= this.caso.valorInmueble.replace(/,/g,"")*0.03
          NombreProducto='ION Liquidez Plus'
          break;
        case 5:
          destino=  'LAND'
          GastosInicialCalculado= this.caso.valorInmueble.replace(/,/g,"")*0.08
          NombreProducto='ION Adquisición de Terreno Plus'
          break;
        case 37:
          destino = 'OFFICES_AND_STORES'
          GastosInicialCalculado= this.caso.valorInmueble.replace(/,/g,"")*0.08
          NombreProducto='ION Oficinas y Locales Plus'
          break;  
      }
           var objGeneraCodigo={
             
              person_type: "NATURAL",//valor fijo
              product: destino,//producto
              required_amount: { "amount": this.caso.montoSolicitado.replace(/,/g,""), "currency": "MXN" },// cantidad requerida
              monthly_income: { "amount": 50000000, "currency": "MXN" },//ingreso mensual valor fijo
              real_estate_value: { "amount": this.caso.valorInmueble.replace(/,/g,""), "currency": "MXN" },//valor inmobiliario
              term: parseInt(plazo)
           } 
        this.$axios.post('https://cotizador.socasesores.com/ApiION/MethodoIon.php',objGeneraCodigo,{headers : {'Content-Type':'application/json; charset=UTF-8'}})
        .then(
            response => { 

                  respuestaData = JSON.parse(response.data.objRespuesta)


                 /* if(respuestaData.notification.level=='ERROR'  ){

                      this.$vs.loading.close('#cotizacion > .con-vs-loading')

                  }else*/
                  
                   if(respuestaData.message=='Internal server error'){

                      this.$vs.loading.close('#cotizacion > .con-vs-loading')
                                 
                      self.$vs.notify({
                        title:'Internal server error ION',
                        text:error,
                        color:'danger',
                        position:'top-right'
                      })
                  }else{ 


                    
                  var respuestaIon = JSON.parse(response.data.objRespuesta)
                  let itemsIon = {}
                  let items=[]
                  let cantidadCotiza = items.length 
                  itemsIon.Cat=parseFloat(respuestaIon.data.cat)
                  itemsIon.Mensualidad=parseFloat(respuestaIon.data.monthly_payment.amount)
                  itemsIon.Tasa=parseFloat(respuestaIon.data.interest_rate)
                  itemsIon.IngresoMinimo= parseFloat(respuestaIon.data.minimum_income) ///parseFloat(respuestaIon.data.monthly_payment.amount)/0.33
                  itemsIon.Enganche=parseFloat(this.caso.valorInmueble.replace(/,/g,"")-this.caso.montoSolicitado.replace(/,/g,""))
                  itemsIon.GastosIniciales=parseFloat(respuestaIon.data.initial_expenses)  ///parseFloat(GastosInicialCalculado)
                  itemsIon.Aforo= calculoAforo
                  itemsIon.Plazo=respuestaIon.data.term*12
                  itemsIon.PagoTotal=respuestaIon.data.total_payment
                  itemsIon.MensualidadPromedio=parseFloat(respuestaIon.data.monthly_payment.amount)
                  itemsIon.MensualidadInicial=parseFloat(respuestaIon.data.monthly_payment.amount)
                  itemsIon.BancoId=21
                  itemsIon.Banco='ION'
                  itemsIon.Producto=NombreProducto
                  itemsIon.Esquema=2
                  itemsIon.Logo='LogoIon_130622.png'
                  itemsIon.PdfAmortizacion= respuestaIon.data.amortization_table.file
                  itemsIon.JsonAmortizacion= respuestaIon.data.amortization_table.raw_data
                  itemsIon.IdIon= respuestaIon.data.id
                  this.items.push(itemsIon)
                  //itemsIon.push(parseInt(this.caso.esquemas[i].value))

                      this.items.sort((a,b) => (a.MensualidadPromedio > b.MensualidadPromedio) ? 1 : ((b.MensualidadPromedio > a.MensualidadPromedio) ? -1 : 0));
                      this.$vs.loading.close('#cotizacion > .con-vs-loading')
                  
                }
                 // console.log(respuestaIon.data.amortization_table.raw_data)
                   this.$vs.loading.close('#cotizacion > .con-vs-loading')

        } ).catch(function (error) {
           self.$vs.notify({
              title:'Ocurrio un errros en apiIon',
              text:error,
              color:'danger',
              position:'top-right'
            })
        })
    },
    mostrarFavoritos(){
      let element = document.getElementById("profile-tab");
      this.muestraFavoritos=element.classList.contains("active")
    },
    mostrarProgramas(){
      this.programas=[]
      if(this.destino==1 || this.destino== 5 || this.destino == 2){
        this.programas = this.todosProgramas
      }else{
        this.programas=[]
         var auxProgramas =[1,9,4]
            var auxProgramaExiste = this.todosProgramas
            for(var j=0;j<auxProgramaExiste.length;j++){
              if(auxProgramas.includes(auxProgramaExiste[j]['Id'])){
                this.programas.push(auxProgramaExiste[j])
              }
            }
      }
    },
    cambia(){
      this.otro= this.otro+1
    },
    numberFormat(number){
      return number.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    dameSolicitante(){
      let self= this
      var objRequestDameSolicitante = {
        strApiKey: this.key,
        strMetodo: 'DameSolicitante',
        objSolicitante: {
         EMail: this.caso.email
        }
      }
      this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.Solicitante = response.data.objContenido
            this.ingreso=response.data.objContenido.Ingresos[0]
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameSolicitante',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    quitar(item){
     // if(this.validaVaciosSantanderExpress){
      let self=this
      var objRequestSolicitaCaso = {
        strApiKey: this.key,
        strMetodo: "BorrarSolicita",
        intVariante: item.VarianteId,
        objCaso:{
          Id:this.id,
        }
      }
      this.$axios.post('/',objRequestSolicitaCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if (response.data.intEstatus == 0) {
            this.$vs.notify({
              title:'Eliminado exitosamente',
              position:'top-right',
            })
             if(item.IdBanco==10){
              this.$store.commit('UPDATE_EXISTE_PRODUCTO_SANTANSER',false)
              this.$router.go(0)
            }
            this.dameCaso()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en BorrarSolicita',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
     /* }else{
        this.$vs.notify({
              title:'Error al intentar eliminar producto',
              text:'Debes vaciar todos los campos del apartado Santander',
              color:'danger',
              position:'top-right'
            })
      }*/
    },
    openFavs(){
      this.RegistraLog()
     // window.open('https://cotizador.socasesores.com/app/imprimir/'+this.id, '_blank');
     // window.open('/imprimir/'+this.id, '_blank');
     this.$router.push('/imprimirFavInmo/'+this.id).catch(() => {})
    },
    generaSolicitud(item){
      let self=this
      var objRequestSolicitaCaso = {
        strApiKey: this.key,
        strMetodo: "GeneraSolicitud",
        objSolicitud:{
          Id:this.id,
          Banco:item.BancoId,
          Variante:item.Id
        }
      }
      this.$axios.post('/',objRequestSolicitaCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if (response.data.intEstatus == 0) {
            this.urlSolicitud=response.data.objContenido.Solicitud
            this.popupSolicitud=true
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en GeneraSolicitud',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    solicitar(item){

      this.s = this.id
      this.o = item.Id

    
      this.dameCaso()
      this.enganche=this.numberFormat(item.Enganche)
      this.bancoSeleccionadp=item.Banco
      this.mensualidadPromedio= this.numberFormat(item.MensualidadPromedio)
      this.tasa= this.numberFormat(item.Tasa)
      this.gastosNotariales= this.numberFormat(item.GastosNotariales)
      this.ingresoMinimo=this.numberFormat(item.IngresoMinimo)
      this.catPromedio=this.numberFormat(item.Cat)
      $('#modalTabla').modal('show');
      this.generaFormatoProductoElegido(item)

    },
    updateCaso(){
      let self=this
      this.$vs.loading({
          container: '#datosCotizacion',
          scale: 0.6
        })
      let e=this.caso  
      this.caso.plazos=[]
      this.caso.esquemas=[]
      for (var i = 0; i < e.plazosACotizar.length; i++) {
        if(typeof e.plazosACotizar[i] == 'object'){
          this.caso.plazos.push(e.plazosACotizar[i].value)
        }else {
          this.caso.plazos.push(e.plazosACotizar[i].substring(0, 2))
        }
      }
      for (var i = 0; i < e.esquemasPago.length; i++) {
        this.caso.esquemas.push(e.esquemasPago[i])
      }  
      if(this.caso.montoCredito!==0){  //no usar replace cuando es 0
        var auxMontoCredito=parseFloat(this.caso.montoCredito.replace(/,/g,""))
        this.caso.montoCredito=auxMontoCredito
      //parseFloat(this.caso.montoCredito.replace(/,/g,""))
      }
      let objRequestRegistraCaso1= {}
      let esquemas=[]
      for (var i = 0; i < this.caso.esquemas.length; i++) {
        esquemas.push(parseInt(this.caso.esquemas[i].value))
      }
      let bancos=[]
      for (var i = 0; i < this.caso.bancos.length; i++) {
        bancos.push((this.caso.bancos[i].value).toString())
      }
      if(this.destino == 19 || this.destino == 25 || this.destino == 22 || this.destino == 33 || this.destino == 34){
        objRequestRegistraCaso1 = {
          strApiKey: this.key,
          strMetodo: 'RegistraCaso',
          objCaso: {
            Id: parseInt(this.id),
            ValorInmueble : this.caso.valorInmueble.replace(/,/g,""),
            SaldoInsoluto : this.caso.montoSolicitado.replace(/,/g,""),
            Plazos : this.caso.plazos,
            Solicitante: this.caso.email,
            Broker:this.caso.ejecutivo,
            Destino : this.destino,
            Estatus:this.caso.estatus,
            Esquemas:esquemas,
            PlazoRestante:this.caso.plazoRestante,
            PagoMensualActual: this.caso.pagoMensualActual.replace(/,/g,""),
            Bancos:bancos,
            Plaza:this.caso.plaza,
            PerfilCliente : this.caso.tasa,
            //GastoNotarial : this.caso.gastosNotarialVersionOrigen:'Web'
          }
        }    
      }else {
        objRequestRegistraCaso1 = {
          strApiKey: this.key,
          strMetodo: 'RegistraCaso',
          objCaso: {
            Id: parseInt(this.id),
            ValorInmueble : parseFloat(this.caso.valorInmueble.replace(/,/g,"")),
            MontoSolicitado : parseFloat(this.caso.montoSolicitado.replace(/,/g,"")),
            Plazos : this.caso.plazos,
            Solicitante: this.caso.email,
            Broker:this.caso.ejecutivo,
            Destino : this.destino,
            Estatus:this.caso.estatus,
            Esquemas:esquemas,
            Bancos:bancos,
            Plaza:this.caso.plaza,
            Programas:[this.caso.programa],
            PerfilCliente : this.caso.tasa,
            //GastoNotarial :this.caso.gastosNotarial
          }
        }
      }
      this.$axios.post('/',objRequestRegistraCaso1,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.registraAdicionales()
            this.cotiza(response.data.objContenido.Id)///response.data.objContenido.Id, response.data.objContenido.Destino  anali
            this.dameCaso(true)
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
            this.$vs.loading.close('#cotizacion > .con-vs-loading')
          }
        }

      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    update(){
      /*if(e.otro){
       this.otro=e.otro
      }*/
     // this.destino= e.destino
     let e=[]
     e=this.caso
    // console.log(e)
      this.caso.plazos=[]
      this.caso.esquemas=[]
      //this.caso.gastosNotarial=e.gastosNotarial
      for (var i = 0; i < e.plazosACotizar.length; i++) {
        if(typeof e.plazosACotizar[i] == 'object'){
          this.caso.plazos.push(e.plazosACotizar[i].value)
        }else {
          this.caso.plazos.push(e.plazosACotizar[i].substring(0, 2))
        }
      }
      for (var i = 0; i < e.esquemasACotizar.length; i++) {
        this.caso.esquemas.push(e.esquemasACotizar[i])
      }

      if(e.destino == 19 || e.destino == 25 || e.destino == 22 || e.destino == 33 || e.destino == 34){
          // this.destino=e.destino
          this.caso.valorInmueble=e.valor
          this.caso.montoSolicitado=e.credito
          this.caso.pagoMensualActual=e.mensualidad
          this.caso.plazoRestante=e.plazoRestante
      }else if (e.destino == 11) {
        this.caso.valorInmueble=e.valor
        this.caso.ssv=e.ssv
        this.caso.creditoInf=e.creditoInf
        this.caso.montoSolicitado='0'
        if(this.caso.programa==9 || this.caso.programa==4){
          this.caso.montoCredito = e.montoCredito
        }
      }else if(e.destino==20){
        this.caso.valorInmueble=e.valor
        this.caso.montoSolicitado=e.credito
        this.caso.valorTerreno=e.valorTerreno
        this.caso.valorProyecto=e.proyecto
      }else {
        //this.destino=e.destino
        this.caso.valorInmueble=e.valor
        this.caso.montoSolicitado=e.credito
      }
      //alert(this.destino)
    },
    filtrar(){
      var tipo = this.filtroModel
      switch(tipo) {
        case '1':
          this.items.sort((a,b) => (a.MensualidadPromedio > b.MensualidadPromedio) ? 1 : ((b.MensualidadPromedio > a.MensualidadPromedio) ? -1 : 0)); //Cambio Mensualidad por MensualidadInicial
          this.filtroName= 'Mensualidad'
          this.filtroId=1
          break;
        case '2':
          this.items.sort((a,b) => (a.Cat > b.Cat) ? 1 : ((b.Cat > a.Cat) ? -1 : 0));
          this.filtroName= 'Cat'
          this.filtroId=2
          break;
        case '3':
          this.items.sort((a,b) => (a.Tasa > b.Tasa) ? 1 : ((b.Tasa > a.Tasa) ? -1 : 0));
          this.filtroName= 'Tasa'
          this.filtroId=3
          break;
        case '4':
          this.items.sort((a,b) => (a.PagoTotal > b.PagoTotal) ? 1 : ((b.PagoTotal > a.PagoTotal) ? -1 : 0));
          this.filtroName= 'Pago Total'
          this.filtroId=4
          break;
        case '5':
          this.items.sort((a,b) => (a.vctPromociones.length < b.vctPromociones.length) ? 1 : ((b.vctPromociones.length < a.vctPromociones.length) ? -1 : 0));
          this.filtroName= 'Promociones'
          this.filtroId=5
          break;
        case '6':
          this.items.sort((a,b) => (a.IngresoMinimo > b.IngresoMinimo) ? 1 : ((b.IngresoMinimo > a.IngresoMinimo) ? -1 : 0));
          this.filtroName= 'Ingreso Mínimo'
          this.filtroId=6
          break;
        case '7':
          this.items.sort((a,b) => (a.AhorroTotal < b.AhorroTotal) ? 1 : ((b.AhorroTotal < a.AhorroTotal) ? -1 : 0));
          this.filtroName= 'Ahorro Total'
          this.filtroId=7
          break;
        case '8':
          this.items.sort((a,b) => (a.MejoraMensualidad < b.MejoraMensualidad) ? 1 : ((b.MejoraMensualidad < a.MejoraMensualidad) ? -1 : 0));
          this.filtroName= 'Mejor Mensualidad'
          this.filtroId=8
          break;
      }
    },
   comprobarFavorito(item) {
			if (item.BancoId == 21 && this.caso.favoritos) {
				for (var i = 0; i < this.caso.favoritos.length; i++) {
					if (this.caso.favoritos[i].BancoId == 21 && this.caso.favoritos[i].Mensualidad == item.Mensualidad && this.caso.favoritos[i].Tasa == item.Tasa) {
						return false
					}
				}
			}else if (item.BancoId == 33 && this.caso.favoritos) {
				for (var i = 0; i < this.caso.favoritos.length; i++) {
					if (this.caso.favoritos[i].BancoId == 33 && this.caso.favoritos[i].Mensualidad == item.Mensualidad && this.caso.favoritos[i].Tasa == item.Tasa && this.caso.favoritos[i].Plazo == item.Plazo ) {
						return false
					}
				}
			} else {
				if (this.caso.favoritos) {
					for (var i = 0; i < this.caso.favoritos.length; i++) {
						if (this.caso.favoritos[i].Id == item.Id) {
							return false
						}
					}
				}
			}

			return true
		},
    quitarFavorito(item){
      let self=this
      let objRequestFavoritoCaso = {
        strApiKey: this.key,
        strMetodo: 'NoFavorito',
        intVariante: item.Id,
        objCaso: {
         Id: this.id,
         IdBanco: item.BancoId,
         IdIon: item.IdIon
        }
      }
      this.$axios.post('/',objRequestFavoritoCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if (response.data.intEstatus == 0) {
            this.dameCaso()
            this.$vs.notify({
              title:`Eliminado exitosamente ${item.Id}`,
              text:`${item.Producto}`,
              position:'top-right',
            })
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en NoFavorito',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    agregarFavorito (item) {
      let self=this
      let objRequestFavoritoCaso = {
        strApiKey: this.key,
        strMetodo: 'Favorito',
        intVariante: item.Id,
        objCaso: {
        Id: this.id,

        IdBanco: item.BancoId,
        IdIon: item.IdIon,
        intCat: item.Cat,
        ValorInmueble : this.caso.valorInmueble.replace(/,/g,""),
        MontoSolicitado: this.caso.montoSolicitado.replace(/,/g,""),
        intMensualidad: item.Mensualidad,
        intTasa: item.Tasa,
        intIngresoMinimo: item.IngresoMinimo,
        intEnganche: item.Enganche,
        intGastosIniciales: item.GastosIniciales,
        intAforo: item.Aforo,
        intPlazo: item.Plazo,
        intPagoTotal: item.PagoTotal,
        intMensualidadPromedio: item.MensualidadPromedio,
        intMensualidadInicial: item.MensualidadInicial,
        intEsquemas: item.Esquemas
             
        }
      }
      this.$axios.post('/',objRequestFavoritoCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if (response.data.intEstatus == 0) {
            this.dameCaso()
            this.$vs.notify({
              title:'Agregado exitosamente',
              text:`${item.Producto}`,
              position:'top-right',
            })
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en NoFavorito',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    async getListProductsMensualidad ({ listProducts }) {

      try {

        const objRequestListProducts = {
          strApiKey: this.key,
          strMetodo: 'ListProductsMensualidad',
          objList: {
            listProducts
          }
        }

        const resp = await this.$axios.post('/', objRequestListProducts, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
        const { intEstatus, data } = resp
        const { objContenido } = data

        if ( !objContenido ) {
          this.$vs.notify({
            title: 'Ocurrio un error en ListProductsMensualidad',
            text: data.strError,
            color: 'danger',
            position: 'top-right',
          })

          return []
        }

        return objContenido
        
      } catch (error) {
        this.$vs.notify({
          title: 'Ocurrio un error de sistema 4',
          text: error,
          color: 'danger',
          position: 'top-right',
        })
      }

      },
    async cotiza(){//Id, destinoId
      try{
            this.$vs.loading({
              container: '#cotizacion',
              scale: 0.6
            })

            let self=this
            this.items=[]
            let objRequestCotizaCaso = {
              strApiKey: this.key,
              strMetodo: 'Cotiza',
              objCaso: {
              Id: this.id,
              //GastoNotarial: this.caso.gastosNotarial
              }
            }
            const resp = await this.$axios.post('/',objRequestCotizaCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
            const { intEstatus, data } = resp

            if ( intEstatus ) {
              this.$vs.notify({
                title: 'Ocurrio un error en Cotiza',
                text: data.strError,
                color: 'danger',
                position: 'top-right',
              })
            }    

            //Mejora ion
            let bancos=[]
            let esquemas=[]

            for (var i = 0; i < this.caso.bancos.length; i++) {
              if(this.caso.bancos[i].value){
              bancos.push(parseInt(this.caso.bancos[i].value))
              }else{
                bancos.push(parseInt(this.caso.bancos[i]))
              }
            }

            for (var i = 0; i < this.caso.esquemas.length; i++) {
              if(this.caso.esquemas[i].value){
              esquemas.push(parseInt(this.caso.esquemas[i].value))
              //console.log(this.caso.esquemas)
              //console.log(this.caso.esquemas[i].value)
              }else{
                esquemas.push(parseInt(this.caso.esquemas[i]))
                //console.log(this.caso.esquemas)
                //console.log(this.caso.esquemas[i])
              }
            }

          // Consultar segunda mensualidad para todos los productos
          const listProducts = data.objContenido ? data.objContenido : []
          const listItems = listProducts.length > 0 ? await this.getListProductsMensualidad({ listProducts }) : listProducts
          this.items = listItems

          //alert(this.caso.programa+'&&'+ esquemas.includes(2) +' &&'+(this.destino) +'&&'+ bancos.includes(21))
            if(this.caso.programa==1 &&  esquemas.includes(2) && (this.destino == 1 || this.destino==5 || this.destino==3 || this.destino==37) && bancos.includes(21)){
                for(var j=0; j<this.caso.plazos.length;j++){

                  if(this.caso.programa==1 &&  esquemas.includes(2) && bancos.includes(21) && (this.destino==5 || this.destino==37) && (this.caso.plazos[j]==5 || this.caso.plazos[j]==6 || this.caso.plazos[j]==7 || this.caso.plazos[j]==8 || this.caso.plazos[j]==9 || this.caso.plazos[j]==10)){
                      this.pruebaIon(this.caso.plazos[j], this.destino)
                    // this.items=response.data.objContenido
                      this.items.sort((a,b) => (a.MensualidadInicialPromedio > b.MensualidadInicialPromedio) ? 1 : ((b.MensualidadInicialPromedio > a.MensualidadInicialPromedio) ? -1 : 0));//Cambio Mensualidad por MensualidadInicial
                      this.filtroName= 'Mensualidad'
                      this.filtroId=1

                      this.$vs.loading({
                        container: '#cotizacion',
                        scale: 0.6
                      }) 

                  }
                  
                  if(this.caso.programa==1 &&  esquemas.includes(2) && bancos.includes(21) && (this.destino==1 || this.destino==3) && (this.caso.plazos[j]==5 || this.caso.plazos[j]==6 || this.caso.plazos[j]==7 || this.caso.plazos[j]==8 || this.caso.plazos[j]==9 || this.caso.plazos[j]==10 || this.caso.plazos[j]==11 || this.caso.plazos[j]==12 || this.caso.plazos[j]==13 || this.caso.plazos[j]==14 || this.caso.plazos[j]==15 || this.caso.plazos[j]==16 || this.caso.plazos[j]==17 || this.caso.plazos[j]==18 || this.caso.plazos[j]==19 || this.caso.plazos[j]==20)){
                      //console.log(this.caso.programa+'&&'+ esquemas.includes(2) +' &&'+(this.destino) +'&&'+ bancos.includes(21))
                    
                      this.pruebaIon(this.caso.plazos[j], this.destino)

                      // console.log(this.items)
                    // this.items=response.data.objContenido
                      this.items.sort((a,b) => (a.MensualidadInicialPromedio > b.MensualidadInicialPromedio) ? 1 : ((b.MensualidadInicialPromedio > a.MensualidadInicialPromedio) ? -1 : 0));//Cambio Mensualidad por MensualidadInicial
                      this.filtroName= 'Mensualidad'
                      this.filtroId=1
                      /*this.$vs.notify({
                        title:`Cotizado y guardado exitosamente`,
                        position:'top-right',
                      })*/

                      this.$vs.loading({
                        container: '#cotizacion',
                        scale: 0.6
                      }) 

                  }else{

                    // console.log(this.items)
                    //this.items=response.data.objContenido
                
                    this.items.sort((a,b) => (a.MensualidadInicialPromedio > b.MensualidadInicialPromedio) ? 1 : ((b.MensualidadInicialPromedio > a.MensualidadInicialPromedio) ? -1 : 0));//Cambio Mensualidad por MensualidadInicial
                    this.filtroName= 'Mensualidad'
                    this.filtroId=1
                    /*this.$vs.notify({
                      title:`Cotizado y guardado exitosamente`,
                      position:'top-right',
                    })*/
                    this.$vs.loading.close('#cotizacion > .con-vs-loading')


                  }
                  this.$vs.loading.close('#cotizacion > .con-vs-loading')
                }
                
            }else{


            for(var x=0; x<this.caso.esquemas.length;x++){
              if(this.caso.esquemas[x].value==2){

                for(var j=0; j<this.caso.plazos.length;j++){

            

                  ///inicia apartado de kredi
                  if (esquemas.includes(2) && bancos.includes(33) && (this.destino == 1) && (this.caso.plazos[j] == 5 || this.caso.plazos[j] == 10 || this.caso.plazos[j] == 15 || this.caso.plazos[j] == 20)){
                        this.ValorInmuebleKredi= parseFloat(this.caso.valorInmueble.replace(/,/g,""))
                        this.EngancheKredi= parseFloat(this.caso.valorInmueble.replace(/,/g,"")) - parseFloat(this.caso.montoSolicitado.replace(/,/g,""))
                        this.PorcentajeEngancheKredi = parseFloat(this.caso.montoSolicitado.replace(/,/g,"")) / parseFloat(this.caso.valorInmueble.replace(/,/g,"")) * 100
                        this.PlazosKredi = this.caso.plazos[j]
                        this.FinanciamientoKredi ='No'
                        this.ComisionAperturaKredi = '0.0'
                        this.EstadoKredi= 20
                        this.GastosNotarialesKredi= '0.05'

                        this.RegistraCasoKredi(this.destino,this.ValorInmuebleKredi, this.EngancheKredi, this.PorcentajeEngancheKredi, this.PlazosKredi, this.FinanciamientoKredi, this.ComisionAperturaKredi, this.EstadoKredi, this.GastosNotarialesKredi)
                          
                        //this.items=response.data.objContenido
                          this.items.sort((a,b) => (a.MensualidadInicialPromedio > b.MensualidadInicialPromedio) ? 1 : ((b.MensualidadInicialPromedio > a.MensualidadInicialPromedio) ? -1 : 0));//Cambio Mensualidad por MensualidadInicial
                          this.filtroName= 'Mensualidad'
                          this.filtroId=1


                          this.$vs.loading({
                            container: '#cotizacion',
                            scale: 0.6
                          }) 

                  }else if (esquemas.includes(2) && bancos.includes(33) && ( this.destino == 19) && (this.caso.plazos[j] == 5 || this.caso.plazos[j] == 10 || this.caso.plazos[j] == 15 || this.caso.plazos[j] == 20 || this.caso.plazos[j] == 25 || this.caso.plazos[j] == 30)){
                      
                        this.ValorInmuebleKredi= parseFloat(this.caso.valorInmueble.replace(/,/g,""))
                        this.EngancheKredi= parseFloat(this.caso.valorInmueble.replace(/,/g,"")) - parseFloat(this.caso.montoSolicitado.replace(/,/g,""))
                        this.PorcentajeEngancheKredi = parseFloat(this.caso.montoSolicitado.replace(/,/g,"")) / parseFloat(this.caso.valorInmueble.replace(/,/g,"")) * 100
                        this.PlazosKredi = this.caso.plazos[j]
                        this.FinanciamientoKredi ='No'
                        this.ComisionAperturaKredi = '0.0'
                        this.EstadoKredi= 20
                        this.GastosNotarialesKredi= '0.05'

                      ///// this.RegistraCasoKredi(this.destino,this.ValorInmuebleKredi, this.EngancheKredi, this.PorcentajeEngancheKredi, this.PlazosKredi, this.FinanciamientoKredi, this.ComisionAperturaKredi, this.EstadoKredi, this.GastosNotarialesKredi)
                          
                        //this.items=response.data.objContenido
                          this.items.sort((a,b) => (a.MensualidadInicialPromedio > b.MensualidadInicialPromedio) ? 1 : ((b.MensualidadInicialPromedio > a.MensualidadInicialPromedio) ? -1 : 0));//Cambio Mensualidad por MensualidadInicial
                          this.filtroName= 'Mensualidad'
                          this.filtroId=1


                          this.$vs.loading({
                            container: '#cotizacion',
                            scale: 0.6
                          }) 

                  }

              
              
              }
              
            
                  ////termina apartado de kredi




              }


            }

            

                // console.log(this.items)
                //this.items=response.data.objContenido                
                this.items.sort((a,b) => (a.MensualidadInicialPromedio > b.MensualidadInicialPromedio) ? 1 : ((b.MensualidadInicialPromedio > a.MensualidadInicialPromedio) ? -1 : 0));//Cambio Mensualidad por MensualidadInicial
                this.filtroName= 'Mensualidad'
                this.filtroId=1
                this.$vs.notify({
                  title:`Cotizado y guardado exitosamente`,
                  position:'top-right',
                })
              this.$vs.loading.close('#cotizacion > .con-vs-loading')              
            }
            

          
        }catch  (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      }
    },
     	RegistraCasoKredi(destinoId,ValorInmuebleKredi,EngancheKredi,PorcentajeEngancheKredi,PlazosKredi,FinanciamientoKredi,ComisionAperturaKredi,EstadoKredi,GastosNotarialesKredi){
			
			let self = this
			let producto = ''
			let respuestaKredi = ''


			if(destinoId==1){
				producto=1
			}else if(destinoId==19){
				producto=2
			}

			var product_application = {
				product_id: producto,
				answers: {
					"fecha": "02/06/2023",
					"valor_del_inmueble": ValorInmuebleKredi,
					"enganche": EngancheKredi,
					"porcentaje_de_enganche": PorcentajeEngancheKredi,
					"plazo_en_anos": PlazosKredi,
					"financiamiento_de_comision_por_apertura": FinanciamientoKredi,
					"porcentaje_comision_por_apertura": ComisionAperturaKredi,
				
					"estado_en_donde_se_encuentra_la_propiedad": EstadoKredi,
					"porcentaje_gastos_notariales":GastosNotarialesKredi

				},
				"async": false,
				"customer_id": "",
				"external_id": "",
				"dry_run": true
			}
			this.$axios
				.post('https://cotizador.socasesores.com/ApiION/MethodoKredi.php', product_application, { headers: { 'Content-Type': 'application/json; charset=UTF-8' } })
				.then((response) => {

				

					respuestaKredi = JSON.parse(response.data.objRespuesta)
		
					
							let itemsKredi = {}
						let items = []
						let cantidadCotiza = items.length
						itemsKredi.Monto= parseFloat(respuestaKredi.data.attributes.answers.monto_del_credito_solicitado)
						itemsKredi.Cat = parseFloat(respuestaKredi.data.attributes.cat)
						itemsKredi.Mensualidad = parseFloat(respuestaKredi.data.attributes.monthly_payment)
						itemsKredi.Tasa = parseFloat(respuestaKredi.data.attributes.answers.tasa_de_interes)
						itemsKredi.IngresoMinimo = parseFloat(respuestaKredi.data.attributes.minimum_income_required) 
						itemsKredi.Enganche = parseFloat(respuestaKredi.data.attributes.hitch) 
						itemsKredi.GastosIniciales = parseFloat(respuestaKredi.data.attributes.answers.valor_avaluo + respuestaKredi.data.attributes.answers.gastos_notariales_aproximados + respuestaKredi.data.attributes.answers.comision_por_apertura) 
						itemsKredi.Avaluo= parseFloat(respuestaKredi.data.attributes.answers.valor_avaluo)
						itemsKredi.ComisionApertura= parseFloat(respuestaKredi.data.attributes.answers.comision_por_apertura)
						itemsKredi.GastosNotariales= parseFloat(respuestaKredi.data.attributes.answers.gastos_notariales_aproximados)
						
						itemsKredi.Aforo = respuestaKredi.data.attributes.answers.aforo
						itemsKredi.Plazo = respuestaKredi.data.attributes.answers.plazo_en_meses
						itemsKredi.PagoTotal = 0
						itemsKredi.MensualidadPromedio = parseFloat(respuestaKredi.data.attributes.monthly_payment)
						itemsKredi.MensualidadInicial = parseFloat(respuestaKredi.data.attributes.monthly_payment)
						itemsKredi.BancoId = 33
						itemsKredi.Banco = 'KREDI'
						if(respuestaKredi.data.attributes.product_id==1){
							itemsKredi.Producto = 'KREDI ADQUISICIÓN DE VIVIENDA'
              itemsKredi.Enganche = parseFloat(respuestaKredi.data.attributes.hitch) 
						}else if(respuestaKredi.data.attributes.product_id==2){
							itemsKredi.Producto = 'KREDI MEJORA DE HIPOTECA ORIGEN VIVIENDA'
              itemsKredi.Enganche = 0
              itemsKredi.MejoraMensualidad=0
              itemsKredi.AhorroTotal =0
						}
						
						itemsKredi.Esquema = 2
						itemsKredi.Logo = 'kredi.png'
						//itemsKredi.PdfAmortizacion = respuestaIon.data.amortization_table.file
						itemsKredi.JsonAmortizacion = respuestaKredi.data.attributes.last_amortization_table
						itemsKredi.IdIon = respuestaKredi.data.id
						this.items.push(itemsKredi)

           			

					this.$vs.loading.close('#cotizacion > .con-vs-loading')
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un errros en api KREDI',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
    dameCaso(flag){
      //this.destino=1 //restaurar para que tome los valores devueltos
      this.caso.programa=1 //restaurar para que tome los valores devueltos
      let self=this
      var objRequestDameCaso = {
        strApiKey: this.key,
        strMetodo: 'DameCaso',
        objCaso: {
         Id: this.id
        }
      }
      this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            let cotizacion=response.data.objContenido;
           // this.caso.plazos=[]
            this.caso.esquemas=[]
             this.caso.bancos=[]
             this.caso.plazosACotizar=[]
             this.caso.esquemasPago=[]
            this.caso.estatus=cotizacion.Estatus
            this.caso.solicitante=cotizacion.Solicitante
            this.destino=cotizacion.Destino
            this.caso.ejecutivo=cotizacion.Broker
            this.caso.email= cotizacion.Solicitante
            this.caso.inmuebleNuevo=cotizacion.Nuevo
            this.caso.plaza=cotizacion.Plaza
            this.caso.estado=cotizacion.EstadoInmueble
            this.caso.tasa=cotizacion.PerfilCliente
            this.caso.bancos=cotizacion.ComparaBancos
            //this.caso.gastosNotarial=cotizacion.GastoNotarial
            this.getBancosEjecutivo(cotizacion.ComparaBancos)
            if(this.destino == 19 ){
              this.caso.saldoInsoluto=cotizacion.SaldoInsoluto.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              this.caso.pagoMensualActual=cotizacion.PagoMensualActual.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            this.caso.valorInmueble=cotizacion.ValorInmueble.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")//.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            /*for (var i = 0; i < cotizacion.Plazos.length; i++) {
              this.caso.plazos.push(cotizacion.Plazos[i])
              this.caso.plazosACotizar.push(cotizacion.Plazos[i])
            }*/
            //this.caso.plazosACotizar=cotizacion.Plazos
            for (var j = 0; j < cotizacion.Plazos.length; j++) {
              this.caso.plazosACotizar.push({label: cotizacion.Plazos[j]+' Años', value:cotizacion.Plazos[j]})
              this.caso.plazos.push(cotizacion.Plazos[j])
              //this.caso.plazosACotizar.push(cotizacion.Plazos[j]+' Años')
            }
           // console.log(this.caso.plazosACotizar)
            for (var i = 0; i < cotizacion.Esquemas.length; i++) {
              if (cotizacion.Esquemas[i] == 2) {
                this.caso.esquemas.push({ label: 'Pago Fijo',  value: 2 })
                this.caso.esquemasPago.push({ label: 'Pago Fijo',  value: 2 })
              }  else if (cotizacion.Esquemas[i] == 1) {
                this.caso.esquemas.push({ label: 'Pago creciente',  value: 1 })
                this.caso.esquemasPago.push({ label: 'Pago creciente',  value: 1 })
              }
            }
            
            this.caso.plazoRestante=cotizacion.PlazoRestante
            this.caso.montoSolicitado=cotizacion.MontoSolicitado.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            this.caso.favoritos=cotizacion.Favoritos
            if (flag) {
              for (var i = 0; i < this.caso.favoritos.length; i++) {
                this.quitarFavorito(this.caso.favoritos[i])
              }
            }
            if (cotizacion.Programas.length) {
              this.caso.programa=cotizacion.Programas[0]
            }
            this.caso.solicitudes=cotizacion.Solicitudes
            for (var i = 0; i < this.caso.solicitudes.length; i++) {
              this.caso.solicitudes[i].Resultado=this.caso.solicitudes[i].Resultado
              this.caso.solicitudes[i].IdCaso=this.caso.solicitudes[i].Caso
              this.caso.solicitudes[i].IdBanco=this.caso.solicitudes[i].IdBanco
              this.caso.solicitudes[i].MensualidadPromedio= this.caso.solicitudes[i].MensualidadPromedio.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              this.caso.solicitudes[i].Cat= this.caso.solicitudes[i].Cat.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            if (this.caso.estado) {
              this.getPlazas()
            }
            if(cotizacion.CreditoInfonavit){
              this.caso.creditoInf=cotizacion.CreditoInfonavit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            if(cotizacion.SubCuentaVivienda){
              this.caso.ssv=cotizacion.SubCuentaVivienda.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            this.caso.montoCredito=(cotizacion.MontoSolicitado).toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            this.dameSolicitante()
            this.calculaPrcentajeCredito()
            this.$vs.loading.close('#datosCotizacion > .con-vs-loading')
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    registraAdicionales(){
      let self=this
      var objRequestAdicionales = {
        strApiKey: this.key,
        strMetodo: 'RegistraAdicionalesSolicitud',
        objCaso: {
         Id: this.id,
        InmuebleGarantia:{
          InmuebleNuevo:this.caso.inmuebleNuevo,
          Estado:this.caso.estado,
        },
      }
    }
      this.$axios.post('/',objRequestAdicionales,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Guardardo exitosamente`,
              position:'top-right'
            })
            this.dameCaso()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraAdicionalesSolicitud',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
        this.linaje = JSON.parse(localStorage.getItem("userInfo")).Linaje;
      }
    },
		getDestinos(){
      let self=this
			var objRequestListaDestinos = {
				strApiKey: this.key,
				strMetodo: 'ListaDestinos',
			}
			this.$axios.post('/',objRequestListaDestinos,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(
				response => {
					if(response.data.intEstatus == 0){
            let destinosAux=[]
            destinosAux= response.data.objContenido
            for(let i=0;i<destinosAux.length;i++){
              if(destinosAux[i].Id==1 || destinosAux[i].Id==19 || destinosAux[i].Id==5 || destinosAux[i].Id==2){
                this.destinos.push(destinosAux[i])
              }
            }
						//this.destinos=response.data.objContenido
					}else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaDestinos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
				}
			).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
			})
		},
		getEstatus(){
      let self=this
			var objRequestListaCasoEstatus = {
				strApiKey: this.key,
				strMetodo: 'ListaCasoEstatus',
			}
			this.$axios.post('/',objRequestListaCasoEstatus,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(
				response => {
					if(response.data.intEstatus == 0){
						this.estatus=response.data.objContenido
					}else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaCasoEstatus',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
				}
			).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
			})
		},
    listaSupervisados(){
      let self=this
      var objRequestListaSupervisados = {
        strApiKey: this.key,
        strMetodo: 'ListaSupervisados',
      }

      this.$axios.post('/',objRequestListaSupervisados,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          this.ejecutivos.push(this.$store.state.AppActiveUser)
          if(response.data.intEstatus == 0){
            for (var i = 0; i < response.data.objContenido.length; i++) {
              this.ejecutivos.push(response.data.objContenido[i])
            }
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getProgramas(){
      let self=this
      var objRequestListaCasoEstatus = {
        strApiKey: this.key,
        strMetodo: 'ListaProgramas',
      }
      this.$axios.post('/',objRequestListaCasoEstatus,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.programas=response.data.objContenido
            this.todosProgramas= this.programas
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaProgramas',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getBancos(){
      let self=this
      var objRequestListaFinancieras = {
        strApiKey: this.key,
        strMetodo: 'ListaFinancieras',
        objEjecutivo: {
          EMail: this.$store.state.AppActiveUser.EMail
        }
      }
      this.$axios.post('/',objRequestListaFinancieras,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            let bancos=response.data.objContenido
            for (var i = 0; i < bancos.length; i++) {
              this.bancos.push({label:bancos[i].Nombre, value: bancos[i].Id})
            }
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaFinancieras',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getBancosEjecutivo(bancosCaso){
      let self=this
      var objRequestListaFinancieras = {
        strApiKey: this.key,
        strMetodo: 'ListaFinancieras',
        objEjecutivo: {
          EMail: this.$store.state.AppActiveUser.EMail
        }
      }
      this.$axios.post('/',objRequestListaFinancieras,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.bancosEjecutivo=[]
            let bancos=response.data.objContenido
            for (var i = 0; i < bancos.length; i++) {
              for (var j = 0; j < bancosCaso.length; j++) {
                if (parseInt(bancosCaso[j]) == bancos[i].Id) {
                  this.bancosEjecutivo.push({label:bancos[i].Nombre, value: bancos[i].Id})
                }
              }
            }
           // console.log(this.bancosEjecutivo)
            this.caso.bancos=this.bancosEjecutivo
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaFinancieras',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getEstados(){
      let self=this
      var objRequestListaEstados = {
        strApiKey: this.key,
        strMetodo: 'ListaEstados',
      }
      this.$axios.post('/',objRequestListaEstados,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.estados=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaEstados',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getPlazas(){
      let self=this
      var objRequestListaEstados = {
        strApiKey: this.key,
        strMetodo: 'ListaPlazas',
        objPlazas:{
          Estado:this.caso.estado
        }
      }
      this.$axios.post('/',objRequestListaEstados,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.plazas=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaPlazas',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getTasas(){
      let self=this
      var objRequestListaEstados = {
        strApiKey: this.key,
        strMetodo: 'ListaPerfilesCliente',
      }
      this.$axios.post('/',objRequestListaEstados,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.tasas=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaPerfilesCliente',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    RegistraLog(flag){
      let self=this
      var objRequestRegistraLog = {
        strApiKey: this.key,
        strMetodo: 'RegistraLog',
        objCaso: {
         Id: this.id
        }
      }
      this.$axios.post('/',objRequestRegistraLog,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    sendCorreoElegido(){
       let self=this
      var objEmail = {
        to: this.caso.solicitante,//this.caso.ejecutivo,
        s:  this.s,
        o:  this.o,
        apiKey:this.key,
        BrokerAsesor: this.brokerSupervisor
      }
      this.$axios.post('https://cotizador.socasesores.com/admin/formatos/emailComparaleProd/SimulacionInmobiliarias.php',objEmail,{headers : {'Content-Type':'application/json; charset=UTF-8'}})
      .then(
        response => {
            //this.$router.push('/login');
            $('#modalTabla').modal('hide');
          this.$vs.notify({
            title:'Se envió correctamente el correo electrónico ',
            
            position:'top-right'
          })
         // this.$router.push('/inmobiliaria');

        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })

      
    },
    descargarPDF(){
       $('#modalTabla').modal('hide');
      var s= this.s
       var o=this.o
      location.href= 'https://cotizador.socasesores.com/admin/formatos/SimulaDestino_Inmobiliaria.php?s='+s+'&o='+o+'&broker='+this.brokerSupervisor
      //this.$router.push('/inmobiliaria');

    }
	}
}
</script>

<style lang="css">
  .v-select ul{
      position: relative !important;
  }
  .filtros{
    display: -webkit-box !important;
    flex-wrap: inherit !important;
  }
  .azulbtn{
    background-color: #1f75fe !important;
  }
    .favoritos{
    border: none !important;
    background: transparent !important;
  }
  .col-md-3.td-modal.margen {
    margin-left: -50px;
}
.descripcion-producto{
  color: #006D4E;
  font-size: 14px;
  font-family: museo sans 300;
}
p.descripcion-producto{
  margin-top:20px;
}
.modal-content .modal-productos{
  /*background-image: url('../../assets/images/pages/back-productos.jpg');*/
  background-size: contain;
  padding:40px;
      position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 0.3rem;
    outline: 0;
    font-weight: 300;
}
.ul-list{
    margin-block-start: 0em !important;
    margin-block-end: 1em !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
    padding-inline-start: 40px !important;
    list-style-type: disc;
    font-weight: bold;
}
.banner-bancos{
  width: 550px;
}

@media screen and (max-width:656px){
  .modal-content .modal-productos{
    padding: 20px;
  }
  .banner-bancos{
    width: 100%;
  }
.texto-verde-imp {
	color: #006D4E;
}

}

/************ Nuevos estilos responsivos ************/
/**** Tabs Crédito - Crédito y opciones tabs ****/
.caso-inmob-tabs .input-has-extra {
  position: relative;
  margin-top: 10px;
}

.caso-inmob-tabs .input-has-extra input {
  margin-top: 0;
}

.caso-inmob-tabs .input-has-extra:after {
  margin-top: -3px;
}

.caso-inmob-tabs .input-has-extra .porcentaje {
  position: absolute;
  right: 0;
  margin: 0;
  top: 3px;
}

.caso-inmob-tabs .input-value {
  padding-right: 4em;
}

.caso-inmob-tabs .input-amount {
  padding-right: 5.5em;
}

/**** Tabs Crédito - Tabla Opciones de Crédito ****/
.table-credit-options .tr-borde {
  display: flex;
  align-items: center;
}

.table-credit-options-header td:nth-child(4) {
  text-align: center;
}

.table-credit-options-body .promocion-productos-lista {
  display: flex;
  justify-content: center;
}

/**** Tabs Crédito - Tabla Amortización ****/
.tabla-inmob-amortizacion .vs-popup--content {
  max-height: calc(100vh - 110px);
}

/**** Tabs Crédito - Modal Detalle ****/
.modal-inmob-detalle .vs-dialog-text {
  display: table;
  width: 100%;
}

.modal-inmob-detalle  .recorte {
  display: block;
  margin: 0 auto;
}

/**** Tabs Crédito - Modal  Resumen simulación ****/
.modal-inmob-resumen .modal-dialog {
  width: 90%;
  max-width: 50em;
  margin: 0 auto;
  z-index: 700;
}

/**** Tabs Solicitante - Ingresos ****/
.form-inmob-ingresos .input-checkbox-wrp .vs-checkbox-primary input:checked+.vs-checkbox {
  background: #006D4E !important;
}

.form-inmob-ingresos .input-checkbox-wrp .vs-icon {
  font-size: 1.2rem;
}

@media screen and (min-width: 916px) {
  .table-credit-options-header td,
  .table-credit-options-body .tr-borde > div {
    text-align: center;
  }
  .table-credit-options-header td:nth-child(2),
  .table-credit-options-header td:nth-child(3),
  .table-credit-options-body .tr-borde > div:nth-child(2),
  .table-credit-options-body .tr-borde > div:nth-child(3) {
    text-align: left;
  }
}

@media screen and (min-width: 916px) and (max-width: 1199px) {
  .table-credit-options svg {
    max-width: 1.5rem;
    max-height: 1.5rem;
  }

  /**** Tabs Crédito - Tabla Opciones de Crédito ****/
  .table-credit-options {
    max-width: 100%;
    overflow-x: auto;
  }

  .table-credit-options .recorte2 {
    max-width: 100%;
    height: auto;
  }

  .table-credit-options svg {
    max-width: 1.75rem;
    max-height: 1.75rem;
  }

  .table-credit-options-header td:first-child,
  .table-credit-options-body .tr-borde > div:first-child {
    width: 11rem;
    min-width: 11rem;
  }

  .table-credit-options-header td:nth-child(2),
  .table-credit-options-body .tr-borde > div:nth-child(2) {
    width: 13rem;
    min-width: 13rem;
  }

  .table-credit-options-header td:nth-child(3),
  .table-credit-options-body .tr-borde > div:nth-child(3) {
    width: 4rem;
    min-width: 4rem;
  }

  .table-credit-options-header td:nth-child(4),
  .table-credit-options-body .tr-borde > div:nth-child(4) {
    width: 11rem;
    min-width: 11rem;
  }

  .table-credit-options-header td:nth-child(5),
  .table-credit-options-body .tr-borde > div:nth-child(5) {
    width: 7rem;
    min-width: 7rem;
  }

  .table-credit-options-header td:nth-child(6),
  .table-credit-options-body .tr-borde > div:nth-child(6),
  .table-credit-options-header td:last-child,
  .table-credit-options-body .tr-borde > div:last-child {
    width: 9rem;
    min-width: 9rem;
  }
}

@media screen and (max-width: 1180px) {
  /**** Tabs Crédito - Crédito y opciones tabs ****/
  .caso-inmob-tabs .folio-tab-header .vx-card__header {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
}

@media screen and (max-width: 915px) {
  /**** Tabs Crédito - Tabla Opciones de Crédito ****/
  .table-credit-options-body .tab-body {
    padding: 0;
  }

  .table-credit-options-header .ocultar-celda {
    display: none;
  }

  .table-credit-options-header .td-modal2 {
    background-color: #FFF;
    padding-top: 20px;
  }

  .table-credit-options-body .card-info-productos {
    background-color: #F5F6F7;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
  }

  .table-credit-options-body .tr-borde {
    width: 100%;
    max-width: 30em;
    flex-wrap: wrap;
    justify-content: center;
  }

  .table-credit-options-body .tr-borde > div {
    order: 1;
  }

  .table-credit-options-body .tr-borde > .logo-productos {
    width: 100%;
    max-width: 13em;
    order: 0;
  }

  .table-credit-options-body .logo-productos img {
    width: 100% !important;
    height: 60px;
  }

  .table-credit-options-body .descripcion-productos {
    text-align: center;
  }

  .table-credit-options-body .descripcion-productos .titulo-productos {
    width: 100%;
    height: auto;
    padding-bottom: 1.25rem;
  }

  .table-credit-options-body .tr-borde > .info-productos {
    width: 100%;
    max-width: 4rem;
    order: 0;
  }

  .table-credit-options-body .info-productos .btn-infor {
    margin: 0;
  }

  .table-credit-options-body .acciones-productos {
    width: 100%;
    padding-top: 1rem;
  }

  .table-credit-options-body .acciones-productos .icono-tabla,
  .table-credit-options-body .acciones-productos .icono-favoritos {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .table-credit-options-body .acciones-productos .leyenda-tabla,
  .table-credit-options-body .acciones-productos .leyenda-favoritos {
    width: auto;
    margin-left: 0;
    margin-top: 0.75rem;
  }

  .table-credit-options-body .acciones-productos .leyenda-tabla br,
  .table-credit-options-body .acciones-productos .leyenda-favoritos br {
    display:none;
  }

  .table-credit-options-body .acciones-productos .icono-fav-wrp {
    width: auto;
  }

  .table-credit-options-body .acciones-productos .favoritos .feather-icon {
    display: block;
  }

  .table-credit-options-body .acciones-productos .favoritos .feather {
    width: 32px !important;
    height: 32px !important;
  }

  .table-credit-options-body .promocion-productos {
    width: 100%;
    padding-top: 1rem;
    margin-left: 0;
  }

  .table-credit-options-body .promocion-productos .promocion-productos-lista {
    flex-direction: column;
  }

  .table-credit-options-body .promocion-productos .con-vs-tooltip {
    width: 100%;
    max-width: 24em;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    padding-bottom: 1rem;
    pointer-events: none;
  }

  .table-credit-options-body .promocion-productos .con-vs-tooltip:last-child {
    padding-bottom: 0;
  }

  .table-credit-options-body .promocion-productos .star {
    fill: #e2b41a;
    min-width: 2.5rem;
    margin-top: -0.4rem;
    padding-right: 0.75rem;
  }

  .table-credit-options-body .favoritos-productos,
  .table-credit-options-body .favoritos-productos .link-view-product-wrp {
    width: auto
  }

  .table-credit-options-body .favoritos-productos .favoritos {
    padding: 1rem;
  }

  /**** Tabs Crédito - Tabla Amortización ****/
  .tabla-inmob-amortizacion .vs-table--tbody,
  .tabla-inmob-totales .vs-table--tbody {
    margin-top: 0 !important;
  }

  /**** Tabs Crédito - Modal  Resumen simulación ****/
  .modal-inmob-resumen-btns-wrp {
    width: 100%;
  }

  .modal-inmob-resumen-btns-wrp .azulbtn,
  .modal-inmob-resumen-btns-wrp .verdebtn {
    width: auto;
  }

  .modal-inmob-resumen-logo {
    padding: 0;
    margin-bottom: 0.5rem !important;
  }

  .modal-inmob-resumen-logo img {
    margin: 0 !important;
    max-width: 100%;
  }
}

@media screen and (max-width: 896px) {
  /**** Tabs Crédito - Crédito y opciones tabs ****/
  .caso-inmob-tabs  .ul-tabs {
    display: flex;
    justify-content: space-between;
  }

  .caso-inmob-tabs  .ul-tabs li {
    margin-bottom: 0;
    width: 49%;
  }

  .caso-inmob-tabs .save-credit-btn-wrp {
    padding-bottom: 1.5rem;
  }

  .caso-inmob-tabs .con-tab .nav-tabs {
    display: flex;
    flex-wrap: wrap;
  }

  .caso-inmob-tabs .vs-tabs--content {
    padding: 0;
  }

  .caso-inmob-tabs .folio-tab-header .vx-card__header {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}

@media screen and (max-width: 667px) {
  .table-credit-options-body .acciones-productos {
    margin-left: 0;
  }
}

@media screen and (max-width: 576px) {
  /**** Tabs Crédito - Crédito y opciones tabs ****/
  .caso-inmob-tabs .fav-btn-wrp {
    width: 100%;
  }
}

</style>
