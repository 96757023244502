<template lang="html">
  <div id="container">
    <div class="form-inmob-ingresos">
      <div class="">
        <vx-card title="Datos requeridos" class="">
          <div class="row w-full">
            <div class="col-md-4">
              <span class="span-placeholder">Ingreso mensual*</span>
              <input class="vs-input" v-model="ingreso.ingresoMensual" name="username"  v-on:keypress="isNumber($event)"/>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Ingresos netos*</span>
              <input class="vs-input" v-model="ingreso.ingresoNeto" name="name"  v-on:keypress="isNumber($event)"/>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Empresa</span>
              <input class="vs-input" v-model="ingreso.empresa" name="username" />
            </div>
          </div>
          <div class="row w-full">  
            <div class="col-md-4">
              <span class="span-placeholder" for="tiempoEmpleo">Tipo de empleo *</span>
              <select name="cars" id="tiempoEmpleo" v-model="ingreso.tipoEmpleo" class="vs-input">
                <option :value='tipo.Id' v-for="tipo in tiposDeEmpleo"  :key="">{{tipo.Nombre}}</option>
              </select>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Fecha de inicio</span>
              <input class="vs-input" type="date" v-model="ingreso.fechaInicio" name="fechaNacimiento" />
            </div>
            <!-- <div class="col-md-4">
              <span class="span-placeholder">Fuente</span>
              <input class="vs-input" v-model="ingreso.fuente" name="username" />
            </div>-->
          </div>
          <br>
          <div class="accordion" id="AccordionIngresos">
  <!--<div class="accordion-item">-->
    <h2 class="accordion-header" id="headingOneING">
      <!--<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneING" aria-expanded="true" aria-controls="collapseOneING">-->
        Adicionales requeridos para llenado de solicitudes
      <!--</button>-->
    </h2>
    <!--<div id="collapseOneING" class="accordion-collapse collapse" aria-labelledby="headingOneING" data-bs-parent="#AccordionIngresos">-->
      <div class="accordion-body">
      <div class="row w-full">
                <div class="col-md-4">
                  <span class="span-placeholder">Domicilio (calle)</span>
                  <input class="vs-input" v-model="ingreso.domicilio" name="name" maxlength="70"/>
                </div>
               <div class="col-md-4">
                 <span class="span-placeholder">Número Exterior</span>
                 <input class="vs-input" v-model="ingreso.numeroExterior" name="name" maxlength="20"/>
                </div>
                <div class="col-md-4">
                  <span class="span-placeholder">Número Interior</span>
                  <input class="vs-input" v-model="ingreso.numeroInterior" name="name" maxlength="20"/>
                </div>
              </div>  
              <div class="row w-full">  
                <div class="col-md-4">
                  <span class="span-placeholder">Colonia</span>
                  <input class="vs-input" v-model="ingreso.colonia" name="name" maxlength="70"/>
                </div>
                <div class="col-md-4">
                  <span class="span-placeholder">Ciudad</span>
                  <input class="vs-input" v-model="ingreso.ciudad" name="username" maxlength="70"/>
                </div>
              </div>
              <div class="row w-full">
                <div class="col-md-4">
                  <span class="span-placeholder">Municipio</span>
                  <input class="vs-input" v-model="ingreso.municipio" name="name" maxlength="70"/>
                </div>
                <div class="col-md-4">
                  <span class="span-placeholder">Código postal</span>
                  <input class="vs-input" v-model="ingreso.codigoPostal" name="name" maxlength="5" v-on:keypress="isNumber($event)"/>
                </div>
                <div class="col-md-4">
                  <span class="span-placeholder">Teléfono (10 dígitos)</span>
                  <input class="vs-input" v-model="ingreso.telefono" maxlength="10" minlength="10" name="name" v-on:keypress="isNumber($event)"/>
                </div>
              </div>
              <div class="row w-full">
                <div class="col-md-4">
                  <span class="span-placeholder" for="formasEnganche">Estado</span>
                  <select name="cars" id="formasEnganche" v-model="ingreso.estado" class="vs-input">
                    <option :value='tipo.Id' v-for="tipo in estados"  :key="">{{tipo.Nombre}}</option>
                  </select>
                </div>
                <div class="col-md-4">
                  <span class="span-placeholder">País</span>
                  <input class="vs-input" v-model="ingreso.pais" name="name" maxlength="60"/>
                </div>
                <div class="col-md-4">
                  <span class="span-placeholder">Puesto</span>
                  <input class="vs-input" v-model="ingreso.puesto" name="name" maxlength="50"/>
                </div>
              </div>
              <div class="row w-full">
                <div class="col-md-4">
                  <span class="span-placeholder">Área o departamento</span>
                  <input class="vs-input" v-model="ingreso.area" name="area" v-validate="'max:50'" maxlength="50"/>
                  <span class="text-danger span-placeholder" v-show="errors.has('area')">Máximo 50 caracteres, son {{ingreso.area.length}}</span>
                </div>
                <div class="col-md-4">
                  <span class="span-placeholder" for="formasEnganche">Tipo de Contrato</span>
                  <select name="cars" id="formasEnganche" v-model="ingreso.tipoContrato" class="vs-input">
                    <option :value='tipo.Id' v-for="tipo in tiposContratos"  :key="">{{tipo.Nombre}}</option>
                  </select>
                </div>
                <div class="col-md-4">
                  <span class="span-placeholder" for="formasEnganche">Giro</span>
                  <select name="cars" id="formasEnganche" v-model="ingreso.giro" class="vs-input">
                    <option :value='tipo.Id' v-for="tipo in giros"  :key="">{{tipo.Nombre}}</option>
                  </select>
                </div>
              </div>
              <div class="row w-full">
                <div class="col-md-4 input-checkbox-wrp">
                  <span class="span-placeholder">Retiene impuestos</span>
                      <vs-checkbox v-model="ingreso.impuestos"></vs-checkbox>
                </div>
                <div class="col-md-4">
                  <span class="span-placeholder" for="formasEnganche">Sector laboral</span>
                  <select name="cars" id="formasEnganche" v-model="ingreso.sector" class="vs-input">
                    <option :value='tipo.Id' v-for="tipo in sectores"  :key="">{{tipo.Nombre}}</option>
                  </select>
                </div>
                <div class="col-md-4">
                  <span class="span-placeholder">Profesión</span>
                  <input class="vs-input" v-model="ingreso.profesion" name="name" maxlength="50"/>
                </div>
              </div>
              <div class="row w-full">
                <div class="col-md-4">
                  <span class="span-placeholder" for="formasEnganche">Comprueba ingresos con</span>
                  <select name="cars" id="formasEnganche" v-model="ingreso.comprobanteIngresos" class="vs-input">
                    <option :value='tipo.Id' v-for="tipo in comprobantes"  :key="">{{tipo.Nombre}}</option>
                  </select>
                </div>
                <div class="col-md-4">
                  <span class="span-placeholder">Jefe inmediato</span>
                  <input class="vs-input" v-model="ingreso.jefe" name="name" maxlength="70" v-on:keypress="soloLetras($event)"/>
                </div>
                <div class="col-md-4">
                  <span class="span-placeholder">Descripción breve de las funciones laborales</span>
                  <input class="vs-input" v-model="ingreso.funciones" name="name" maxlength="70"/>
                </div>
              </div>  
      </div>
    <!--</div>-->
 <!-- </div>-->
  <!--<div class="accordion-item">-->
    <h2 class="accordion-header" id="headingTwoING">
      <!--<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwoING" aria-expanded="false" aria-controls="collapseTwoING">-->
        Empleo anterior
      <!--</button>-->
    </h2>
    <!--<div id="collapseTwoING" class="accordion-collapse collapse" aria-labelledby="headingTwoING" data-bs-parent="#AccordionIngresos">-->
      <div class="accordion-body">
        <div class="row w-full">
                <div class="col-md-6">
                  <span class="span-placeholder">Empresa o actividad de su empleo anterior</span>
                  <input class="vs-input" v-model="anterior.empresaAnterior" name="name" maxlength="70"/>
                </div>
                <div class="col-md-6">
                  <span class="span-placeholder">Causa de separación (renuncia, despido, etc)</span>
                  <input class="vs-input" v-model="anterior.separacion" name="name" maxlength="70"/>
                </div>
        </div>        
        <div class="row w-full">        
                <div class="col-md-6">
                  <span class="span-placeholder">Fecha de inicio en el empleo anterior</span>
                  <input class="vs-input" v-model="anterior.inicioAnterior" name="name" type="date"/>
                </div>
                <div class="col-md-6">
                  <span class="span-placeholder">Fecha de salida en el empleo anterior</span>
                  <input class="vs-input" v-model="anterior.salidaAnterior" name="name" type="date"/>
                </div>
        </div>        
        <div class="row w-full">        
                <div class="col-md-6">
                  <span class="span-placeholder">Teléfono</span>
                  <input class="vs-input" v-model="anterior.telefono" name="name" maxlength="10" v-on:keypress="isNumber($event)" />
                </div>
                <div class="col-md-6">
                  <span class="span-placeholder">Jefe</span>
                  <input class="vs-input" v-model="anterior.jefe" name="name" maxlength="70" v-on:keypress="soloLetras($event)"/>
                </div>
        </div>        
        <div class="row w-full">        
                <div class="col-md-4">
                  <span class="span-placeholder" for="formasEnganche">Tipo de Contrato</span>
                  <select name="cars" id="formasEnganche" v-model="anterior.contrato" class="vs-input">
                    <option :value='tipo.Id' v-for="tipo in tiposContratos"  :key="">{{tipo.Nombre}}</option>
                  </select>
                </div>
                <div class="col-md-4">
                  <span class="span-placeholder" for="formasEnganche">Sector laboral</span>
                  <select name="cars" id="formasEnganche" v-model="anterior.sector" class="vs-input">
                    <option :value='tipo.Id' v-for="tipo in sectores"  :key="">{{tipo.Nombre}}</option>
                  </select>
                </div>
                <div class="col-md-4">
                  <span class="span-placeholder" for="tiempoEmpleo">Tipo de empleo *</span>
                  <select name="cars" id="tiempoEmpleo" v-model="anterior.tipo" class="vs-input">
                    <option :value='tipo.Id' v-for="tipo in tiposDeEmpleo"  :key="">{{tipo.Nombre}}</option>
                  </select>
                </div>
              </div>
              <br>
              <input class="vs-input" v-model="IdIngreso" name="name" hidden/>
              <!--<vs-button color="primary" @click="registraEmpleoAnterior()">Guardar empleo anterior</vs-button>-->
      </div>
    <!--</div>-->
  <!--</div>-->
</div>
          
          <br>
          <button class="btn btn-second" @click="registraIngreso()" :disabled="!validateForm">Guardar ingreso</button>
         
        </vx-card>
      </div>
    </div>
    <vs-popup fullscreen title="Editar Ingresos" :active.sync="popupActive">
      <vx-card title="Datos requeridos" class="">
        <div class="row w-full">
          <div class="col-md-4">
            <span class="span-placeholder">Ingreso mensual*</span>
            <input class="vs-input" v-model="ingreso.ingresoMensual" name="username" @change="formateaIngresoMensual()" v-on:keyup="validaIngersoMensual()" v-on:keypress="isNumber($event)"/>
          </div>
          <div class="col-md-4">
            <span class="span-placeholder">Ingresos netos*</span>
            <input class="vs-input" v-model="ingreso.ingresoNeto" name="name" v-on:keypress="isNumber($event)" @change="formateaIngresoNeto()" v-on:keyup="validaIngresoNeto()"/>
          </div>
          <div class="col-md-4">
            <span class="span-placeholder">Empresa*</span>
            <input class="vs-input" v-model="ingreso.empresa" name="username" />
          </div>
          <div class="col-md-4">
            <span class="span-placeholder" for="tiempoEmpleo">Tipo de empleo *</span>
            <select name="cars" id="tiempoEmpleo" v-model="ingreso.tipoEmpleo" class="vs-input">
              <option :value='tipo.Id' v-for="tipo in tiposDeEmpleo"  :key="">{{tipo.Nombre}}</option>
            </select>
          </div>
          <div class="col-md-4">
            <span class="span-placeholder">Fecha de inicio</span>
            <input class="vs-input" type="date" v-model="ingreso.fechaInicio" name="fechaNacimiento" />
          </div>
        </div>
        <br>
        <h4>Adicionales</h4>
        <div class="row w-full">
          <div class="col-md-4">
            <span class="span-placeholder">Domicilio (calle )</span>
            <input class="vs-input" v-model="ingreso.domicilio" name="name" />
          </div>
           <div class="col-md-4">
             <span class="span-placeholder">Número exterior</span>
            <input class="vs-input" v-model="ingreso.numeroExterior" name="name" />
          </div>
          <div class="col-md-4">
            <span class="span-placeholder">Número interior</span>
            <input class="vs-input" v-model="ingreso.numeroInterior" name="name" />
          </div>
          <div class="col-md-4">
            <span class="span-placeholder">Colonia</span>
            <input class="vs-input" v-model="ingreso.colonia" name="name" />
          </div>
          <div class="col-md-4">
            <span class="span-placeholder">Ciudad</span>
            <input class="vs-input" v-model="ingreso.ciudad" name="username" />
          </div>
        </div>
        <div class="row w-full">
          <div class="col-md-4">
            <span class="span-placeholder">Municipio</span>
            <input class="vs-input" v-model="ingreso.municipio" name="name" />
          </div>
          <div class="col-md-4">
            <span class="span-placeholder">Código postal</span>
            <input class="vs-input" v-model="ingreso.codigoPostal" name="name" />
          </div>
          <div class="col-md-4">
            <span class="span-placeholder">Teléfono (10 dígitos)</span>
            <input class="vs-input" v-model="ingreso.telefono" name="name" />
          </div>
        </div>
        <div class="row w-full">
          <div class="col-md-4">
            <span class="span-placeholder" for="formasEnganche">Estado</span>
            <select name="cars" id="formasEnganche" v-model="ingreso.estado" class="vs-input">
              <option :value='tipo.Id' v-for="tipo in estados"  :key="">{{tipo.Nombre}}</option>
            </select>
          </div>
          <div class="col-md-4">
            <span class="span-placeholder">País</span>
            <input class="vs-input" v-model="ingreso.pais" name="name" />
          </div>
          <div class="col-md-4">
            <span class="span-placeholder">Puesto</span>
            <input class="vs-input" v-model="ingreso.puesto" name="name" />
          </div>
        </div>
        <div class="row w-full">
          <div class="col-md-4">
            <span class="span-placeholder">Área o departamento</span>
            <input class="vs-input" v-model="ingreso.area" name="area" v-validate="'max:32'"/>
            <span class="text-danger span-placeholder" v-show="errors.has('area')">Máximo 32 caracteres, son {{ingreso.area.length}}</span>
          </div>
          <div class="col-md-4">
            <span class="span-placeholder" for="formasEnganche">Tipo de Contrato</span>
            <select name="cars" id="formasEnganche" v-model="ingreso.tipoContrato" class="vs-input">
              <option :value='tipo.Id' v-for="tipo in tiposContratos"  :key="">{{tipo.Nombre}}</option>
            </select>
          </div>
          <div class="col-md-4">
            <span class="span-placeholder" for="formasEnganche">Giro</span>
            <select name="cars" id="formasEnganche" v-model="ingreso.giro" class="vs-input">
              <option :value='tipo.Id' v-for="tipo in giros"  :key="">{{tipo.Nombre}}</option>
            </select>
          </div>
        </div>
        <div class="row w-full">
          <div class="col-md-4">
            <span class="span-placeholder">Retiene impuestos</span>
                <vs-checkbox v-model="ingreso.impuestos"></vs-checkbox>
          </div>
          <div class="col-md-4">
            <span class="span-placeholder" for="formasEnganche">Sector laboral</span>
            <select name="cars" id="formasEnganche" v-model="ingreso.sector" class="vs-input">
              <option :value='tipo.Id' v-for="tipo in sectores"  :key="">{{tipo.Nombre}}</option>
            </select>
          </div>
          <div class="col-md-4">
            <span class="span-placeholder">Profesión</span>
            <input class="vs-input" v-model="ingreso.profesion" name="name" />
          </div>
        </div>
        <div class="row w-full">
          <div class="col-md-4">
            <span class="span-placeholder" for="formasEnganche">Comprueba ingresos con</span>
            <select name="cars" id="formasEnganche" v-model="ingreso.comprobanteIngresos" class="vs-input">
              <option :value='tipo.Id' v-for="tipo in comprobantes"  :key="">{{tipo.Nombre}}</option>
            </select>
          </div>
          <div class="col-md-4">
            <span class="span-placeholder">Jefe inmediato</span>
            <input class="vs-input" v-model="ingreso.jefe" name="name" />
          </div>
          <div class="col-md-4">
            <span class="span-placeholder">Funciones del empleo</span>
            <input class="vs-input" v-model="ingreso.funciones" name="name" />
          </div>
        </div>
        <br>
        <div class="row w-full">
          <div class="col-md-4">
        <vs-button color="primary" @click="editarIngreso()" :disabled="!validateForm">Editar ingreso</vs-button>
          </div>
        </div>
      </vx-card>
    </vs-popup>
  </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
  components: {
    'v-select': vSelect,
  },
  data(){
		return{
      popupActive: false,
      key:'',
      IdIngreso:'',
      ingreso:{
        ingresoId:'',
        ingresoNeto:'',
        ingresoMensual:'',
        tipoEmpleo:'',
        empresa:'',
        fuente:'',
        puesto:'',
        giro:'',
        tipoContrato:'',
        impuestos:false,
        fechaInicio:'',
        domicilio:'',
        colonia:'',
        numeroExterior:'',
        numeroInterior:'',
        ciudad:'',
        municipio:'',
        codigoPostal:'',
        telefono:'',
        area:'',
        sector:'',
        profesion:'',
        comprobanteIngresos:'',
        estado:'',
        pais:'',
        jefe:'',
      },
      anterior:{
        empresaAnterior:'',
        inicioAnterior:'',
        salidaAnterior:'',
        separacion:'',
        funciones:'',
        telefono:'',
        jefe:'',
        contrato:'',
        tipo:'',
        sector:''
      },
      solicitante:{},
      ingresos:[],
      tiposDeEmpleo:[],
      giros:[],
      estados:[],
      comprobantes:[],
      tiposContratos:[],
      sectores:[],
      id:'',
      email:'',
      fechaSinValidar:false
    }
  },
  watch: {
    ingresoNeto: function(newValue) {
      this.ingreso.ingresoNeto= '$'+newValue.replace(/\D/g, "").replace(/([0-9])([0-9]{2})$/, '$1.$2').replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",");
    },
    ingresoMensual: function(newValue) {
      this.ingreso.ingresoMensual= '$'+newValue.replace(/\D/g, "").replace(/([0-9])([0-9]{2})$/, '$1.$2').replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",");
    },
    popupActive:function(){
      if (this.popupActive == false) {
        this.limpiaForm()
      }
    },
  },
  computed:{
    ingresoNeto(){
      return this.ingreso.ingresoNeto
    },
    ingresoMensual(){
      return this.ingreso.ingresoMensual
    },
    validateForm(){
      return this.ingreso.tipoEmpleo != '' && this.ingreso.ingresoNeto != '' && this.ingreso.ingresoMensual != '' && this.ingreso.empresa != '' && ((this.ingreso.tipoEmpleo != 48 && this.ingreso.fechaInicio != '')|| this.ingreso.tipoEmpleo == 48)
    }
  },
  mounted(){
    this.id=this.$route.params.id
    this.getApikey()
    this.getEstados()
    this.getListaTipoEmpleos()
    this.getTipoIdentificaciones()
    this.getGiros()
    this.getSectoresRed()
    this.getTiposContratos()
    this.dameCaso()
  },
  methods:{
    soloLetras: function(event) {
        var regex = new RegExp("^[áéíóúüña-zÑÁÉÍÓÚA-Z ]+$");
        var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (!regex.test(key)) {
          event.preventDefault();
          return false;
          }
    },
    validaIngresoNeto() {
      this.ingreso.ingresoNeto=this.ingreso.ingresoNeto.replace(/,/g,"")
      const regex = /^(\d{1,8})(\.\d{0,2})?$/  
      const matches = regex.test(this.ingreso.ingresoNeto);
      if(matches===true){
      this.ingreso.ingresoNeto= this.ingreso.ingresoNeto
      }else{
        this.ingreso.ingresoNeto=this.ingreso.ingresoNeto.replace(/.$/, '')
      }
    },
    formateaIngresoNeto(){
      if(this.ingreso.ingresoNeto==''){
        this.ingreso.ingresoNeto=0
      }
      this.ingreso.ingresoNeto = parseFloat(this.ingreso.ingresoNeto).toFixed(2)
      const exp = /(\d)(?=(\d{3})+(?!\d))/g;  
      const rep = '$1,';
      this.ingreso.ingresoNeto= this.ingreso.ingresoNeto.toString().replace(exp,rep);
    },
     validaIngersoMensual() {
      this.ingreso.ingresoMensual=this.ingreso.ingresoMensual.replace(/,/g,"")
      const regex = /^(\d{1,8})(\.\d{0,2})?$/  
      const matches = regex.test(this.ingreso.ingresoMensual);
      if(matches===true){
      this.ingreso.ingresoMensual= this.ingreso.ingresoMensual
      }else{
        this.ingreso.ingresoMensual=this.ingreso.ingresoMensual.replace(/.$/, '')
      }
    },
    formateaIngresoMensual(){
      if(this.ingreso.ingresoMensual==''){
        this.ingreso.ingresoMensual=0
      }
      this.ingreso.ingresoMensual = parseFloat(this.ingreso.ingresoMensual).toFixed(2)
      const exp = /(\d)(?=(\d{3})+(?!\d))/g;  
      const rep = '$1,';
      this.ingreso.ingresoMensual= this.ingreso.ingresoMensual.toString().replace(exp,rep);
    },
    numberFormat(num){
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    registraEmpleoAnterior(){
      var objEmpleoAnterior= {
        strApiKey:this.key,
        strMetodo:'RegistraSolicitanteEmpleoAnterior',
        objSolicitante: {
          EMail:this.email,
          Empleo:{
            NombreEmpleoAnterior:this.anterior.empresaAnterior,
            JefeEmpleoAnterior:this.anterior.jefe,
            TelefonoEmpleoAnterior:this.anterior.telefono,
            CausaSeparacionEmpleoAnterior:this.anterior.separacion,
            FechaIngresoEmpleoAnterior:this.anterior.inicioAnterior,
            FechaSalidaEmpleoAnterior:this.anterior.salidaAnterior,
            DescripcionFuncionesLaborales:this.anterior.funciones,
            TipoContratoEmpleoAnterior: this.anterior.contrato,
            TipoEmpleoAnterior: this.anterior.tipo,
            SectorRedHipotecariaEmpleoAnterior: this.anterior.sector
          }
        }
      }
      this.$axios.post('/',objEmpleoAnterior,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Datos guardados exitosamente`,
              position:'top-right'
            })
            this.dameSolicitante()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraSolicitanteEmpleoAnterior',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    editarIngreso(){
      let self= this
      var objRequestRegistraCuentasBancarias = {
        strApiKey:this.key,
        strMetodo:'EditarIngresos',
        objSolicitante:{
            EMail:this.email,
            Ingresos:[
              {
                Id:this.IdIngreso,
                IngresosMensuales:parseFloat(this.ingreso.ingresoMensual.replace(/,/g,"").replace(/\$/g,"")),
                IngresosNetos:parseFloat(this.ingreso.ingresoNeto.replace(/,/g,"").replace(/\$/g,"")),
                TipoEmpleo:this.ingreso.tipoEmpleo,
                Empresa : this.ingreso.empresa,
                Fuente : this.ingreso.fuente,
                Puesto : this.ingreso.puesto,
                Giro : this.ingreso.giro,
                TipoContrato : this.ingreso.tipoContrato,
                RetieneImpuestos : this.ingreso.impuestos,
                FechaInicio : this.ingreso.fechaInicio,
                Domicilio : this.ingreso.domicilio,
                Colonia : this.ingreso.colonia,
                NumeroExterior: this.ingreso.numeroExterior,
                NumeroInterior:this.ingreso.numeroInterior,
                Ciudad : this.ingreso.ciudad,
                Municipio : this.ingreso.municipio,
                CodigoPostal : this.ingreso.codigoPostal,
                Telefono : this.ingreso.telefono,
                AreaDepartamento : this.ingreso.area,
                SectorRedHipotecaria : this.ingreso.sector,
                Profesion : this.ingreso.profesion,
                ComprobanteIngresos : this.ingreso.comprobanteIngresos,
                Estado : this.ingreso.estado,
                Pais : this.ingreso.pais,
                JefeInmediatoNombre : this.ingreso.jefe,
              }
            ],
            Empleo:{
                ActividadesEmpleoActual:this.ingreso.funciones,
          }
        }
      }
      this.$axios.post('/',objRequestRegistraCuentasBancarias,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Datos editados exitosamente`,
              position:'top-right'
            })
            //this.popupActive=false
            this.registraEmpleoAnterior()
            this.limpiaForm()
            this.dameSolicitante()
            this.$vs.loading.close('#container > .con-vs-loading')
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraCuentasBancarias',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    editar(ingreso){
      this.ingreso.ingresoId=ingreso.Id
      this.ingreso.tipoEmpleo=ingreso.TipoEmpleoId
      this.ingreso.ingresoNeto=ingreso.IngresosNetos.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      this.ingreso.ingresoMensual=ingreso.IngresosMensuales.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      this.ingreso.empresa=ingreso.Empresa
      this.ingreso.fuente=ingreso.Fuente
      this.ingreso.puesto=ingreso.Puesto
      this.ingreso.giro=ingreso.GiroId
      this.ingreso.tipoContrato=ingreso.TipoContratoId
      this.ingreso.impuestos=ingreso.RetieneImpuestos
      this.ingreso.fechaInicio= ingreso.FechaInicio
      this.ingreso.domicilio=ingreso.Domicilio
      this.ingreso.colonia=ingreso.Colonia
      this.ingreso.numeroExterior=ingreso.NumeroExterior
      this.ingreso.numeroInterior=ingreso.NumeroInterior
      this.ingreso.ciudad=ingreso.Ciudad
      this.ingreso.municipio=ingreso.Municipio
      this.ingreso.codigoPostal=ingreso.CodigoPostal
      this.ingreso.telefono=ingreso.Telefono
      if (ingreso.AreaDepartamento) {
        this.ingreso.area=ingreso.AreaDepartamento
      }else {
        this.ingreso.area=''
      }
      this.ingreso.sector=ingreso.SectorRedHipotecariaId
      this.ingreso.profesion=ingreso.Profesion
      this.ingreso.comprobanteIngresos=ingreso.ComprobanteIngresosId
      this.ingreso.estado=ingreso.EstadoId
      this.ingreso.pais=ingreso.Pais
      this.ingreso.jefe=ingreso.JefeInmediatoNombre
      //this.popupActive=true
    },
    eliminar(id){
      var eliminarCuentas = {
        strApiKey:this.key,
        strMetodo:'BorrarIngresos',
        objSolicitante:{
            EMail:this.email,
            Ingresos:[
              {
                Id:id
              }
            ]
          }
      }
      this.$axios.post('/',eliminarCuentas,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Se eliminó exitosamente`,
              position:'top-right'
            })
            this.dameSolicitante()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en BorrarIngresos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    registraIngreso(){
      this.$vs.loading({
        container: '#container',
        scale: 0.6
      })
      let id=this.IdIngreso
      if(id>0){
        this.editarIngreso()
      }else{
      let self=this
      var objRequestListaCasos = {
        strApiKey:this.key,
        strMetodo:'RegistraIngresos',
        objSolicitante:{
            EMail:this.email,
            Ingresos:[
              {
                IngresosMensuales:parseFloat(this.ingreso.ingresoMensual.replace(/,/g,"").replace(/\$/g,"")),
                 IngresosNetos:parseFloat(this.ingreso.ingresoNeto.replace(/,/g,"").replace(/\$/g,"")),
                 TipoEmpleo:this.ingreso.tipoEmpleo,
                 Empresa : this.ingreso.empresa,
                 Fuente : this.ingreso.fuente,
                 Puesto : this.ingreso.puesto,
                 Giro : this.ingreso.giro,
                 TipoContrato : this.ingreso.tipoContrato,
                 RetieneImpuestos : this.ingreso.impuestos,
                 FechaInicio : this.ingreso.fechaInicio,
                 Domicilio : this.ingreso.domicilio,
                 Colonia : this.ingreso.colonia,
                 NumeroExterior: this.ingreso.numeroExterior,
                 NumeroInterior: this.ingreso.numeroInterior,
                 Ciudad : this.ingreso.ciudad,
                 Municipio : this.ingreso.municipio,
                 CodigoPostal : this.ingreso.codigoPostal,
                 Telefono : this.ingreso.telefono,
                 AreaDepartamento : this.ingreso.area,
                 SectorRedHipotecaria : this.ingreso.sector,
                 Profesion : this.ingreso.profesion,
                 ComprobanteIngresos : this.ingreso.comprobanteIngresos,
                 Estado : this.ingreso.estado,
                 Pais : this.ingreso.pais,
                 JefeInmediatoNombre : this.ingreso.jefe,
              }
            ],
            Empleo:{
              ActividadesEmpleoActual:this.ingreso.funciones,
          }
        }
      }
      this.$axios.post('/',objRequestListaCasos,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Datos guardados exitosamente`,
              position:'top-right'
            })
            this.registraEmpleoAnterior()
            this.limpiaForm()
            this.dameSolicitante()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraIngresos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
          this.$vs.loading.close('#container > .con-vs-loading')
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
      }
    },
    dameCaso(){
      let self=this
      var objRequestDameCaso = {
        strApiKey: this.key,
        strMetodo: 'DameCaso',
        objCaso: {
         Id: this.id
        }
      }
      this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.email= response.data.objContenido.Solicitante
            this.dameSolicitante()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameSolicitante(){
      let self=this
      var objRequestDameSolicitante = {
        strApiKey: this.key,
        strMetodo: 'DameSolicitante',
        objSolicitante: {
         EMail: this.email
        }
      }
      this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.solicitante=response.data.objContenido
            this.ingresos=response.data.objContenido.Ingresos
            //console.log(this.ingresos)
            for(let i=0; i<this.ingresos.length;i++){
            this.editar(this.ingresos[i])
            this.IdIngreso=this.ingresos[i].Id
            }
            this.ingreso.funciones=this.solicitante.DescripcionFuncionesLaborales
            this.anterior.empresaAnterior=this.solicitante.NombreEmpleoAnterior
            this.anterior.inicioAnterior=this.solicitante.FechaIngresoEmpleoAnterior
            this.anterior.salidaAnterior=this.solicitante.FechaSalidaEmpleoAnterior
            this.anterior.separacion=this.solicitante.CausaSeparacionEmpleoAnterior
            this.anterior.jefe=this.solicitante.JefeEmpleoAnterior
            this.anterior.telefono=this.solicitante.TelefonoEmpleoAnterior
            this.anterior.contrato=this.solicitante.TipoContratoEmpleoAnterior
            this.anterior.tipo=this.solicitante.TipoEmpleoAnterior
            this.anterior.sector=this.solicitante.SectorRedHipotecariaEmpleoAnterior
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameSolicitante',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getListaTipoEmpleos(){
      let self=this
      var objRequestListaCasoEstatus = {
        strApiKey: this.key,
        strMetodo: 'ListaTipoEmpleos',
      }
      this.$axios.post('/',objRequestListaCasoEstatus,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.tiposDeEmpleo=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaTipoEmpleos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getEstados(){
      let self=this
      var objRequestListaCasoEstatus = {
        strApiKey: this.key,
        strMetodo: 'ListaEstados',
      }
      this.$axios.post('/',objRequestListaCasoEstatus,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.estados=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaEstados',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getTipoIdentificaciones(){
      let self=this
      var objRequestListaTipoIdentificaciones = {
        strApiKey: this.key,
        strMetodo: 'ListaComprobantesIngreso',
      }
      this.$axios.post('/',objRequestListaTipoIdentificaciones,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.comprobantes=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaTipoIdentificaciones',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getGiros(){
      let self=this
      var objRequestListaGirosIngreso = {
        strApiKey: this.key,
        strMetodo: 'ListaGirosIngreso',
      }
      this.$axios.post('/',objRequestListaGirosIngreso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.giros=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaGirosIngreso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getTiposContratos(){
      let self=this
      var objRequestListaTipoContratos = {
        strApiKey: this.key,
        strMetodo: 'ListaTipoContratos',
      }
      this.$axios.post('/',objRequestListaTipoContratos,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.tiposContratos=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaTipoContratos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getSectoresRed(){
      let self=this
      var objRequestListaSectorRedHipotecaria = {
        strApiKey: this.key,
        strMetodo: 'ListaSectorRedHipotecaria',
      }
      this.$axios.post('/',objRequestListaSectorRedHipotecaria,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.sectores=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaSectorRedHipotecaria',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    limpiaForm(){
      this.ingreso.tipoEmpleo=''
      this.ingreso.ingresoNeto=''
      this.ingreso.ingresoMensual=''
      this.ingreso.empresa=''
      this.ingreso.fuente=''
      this.ingreso.puesto=''
      this.ingreso.giro=''
      this.ingreso.tipoContrato=''
      this.ingreso.impuestos=false
      this.ingreso.fechaInicio=''
      this.ingreso.domicilio=''
      this.ingreso.colonia=''
      this.ingreso.numeroExterior=''
      this.ingreso.numeroInterior=''
      this.ingreso.ciudad=''
      this.ingreso.municipio=''
      this.ingreso.codigoPostal=''
      this.ingreso.telefono=''
      this.ingreso.area=''
      this.ingreso.sector=''
      this.ingreso.profesion=''
      this.ingreso.comprobanteIngresos=''
      this.ingreso.estado=''
      this.ingreso.pais=''
      this.ingreso.jefe=''
      this.ingreso.funciones=''
      // this.ingreso.empresaAnterior=''
      // this.ingreso.duracionAnios=''
      // this.ingreso.duracionMeses=''
    },
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      }
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  }
}
</script>

<style lang="css" scoped>
.v-select{
    width: 100%;
}
.label-select{
  margin-top: 3%;
}
.con-vs-checkbox{
  margin-top: 5%;
}
</style>
