<template>

  <div class="layout--main caso-inmob" >
    <template >
      
    </template>
    <!-- /Navbar -->

      <div class="">

        <div class="router-view">
          <div class="router-content">


              <div v-if="$route.meta.breadcrumb || $route.meta.pageTitle" class="router-header flex flex-wrap items-center mb-6">
                <div
                  class="content-area__heading"
                  :class="{'pr-4 border-0 md:border-r border-solid border-grey-light' : $route.meta.breadcrumb}">
                  <h2 class="mb-1">{{ routeTitle }}</h2>
                </div>

                <!-- BREADCRUMB -->
                <vx-breadcrumb class="ml-4 md:block hidden" v-if="$route.meta.breadcrumb" :route="$route" :isRTL="$vs.rtl" />

                <!-- DROPDOWN -->
                <vs-dropdown vs-trigger-click class="ml-auto md:block hidden cursor-pointer">
                  <vs-button radius icon="icon-settings" icon-pack="feather" />

                  <vs-dropdown-menu class="w-32">
                    <vs-dropdown-item>
                      <div @click="$router.push('/pages/profile').catch(() => {})" class="flex items-center">
                        <feather-icon icon="UserIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />
                        <span>Profile</span>
                      </div>
                    </vs-dropdown-item>
                    <vs-dropdown-item>
                      <div @click="$router.push('/apps/todo').catch(() => {})" class="flex items-center">
                        <feather-icon icon="CheckSquareIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />
                        <span>Tasks</span>
                      </div>
                    </vs-dropdown-item>
                    <vs-dropdown-item>
                      <div @click="$router.push('/apps/email').catch(() => {})" class="flex items-center">
                        <feather-icon icon="MailIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />
                        <span>Inbox</span>
                      </div>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>

                </vs-dropdown>

              </div>

            <div class="caso-inmob-tabs vistaInt">
              <div v-if="consulta">
        <vs-tabs class="caso" :position="'left'"  id="" >
          
          <vs-tab label="Crédito">
            <div>
              <Credito :validaVaciosSantanderExpress="camposVaciosSantanderExpress" v-on:existeSolicitadoSantander="existeSolicitadoSantander($event)"/>
            </div>
          </vs-tab>
          <vs-tab label="Solicitante">
            <div>
              <Personales/>
            </div>
          </vs-tab>
          <!--<vs-tab label="Coacreditado">
            <div>
              <Coacreditado/>
            </div>
          </vs-tab>
          <vs-tab label="Notas">
            <div>
              <Notas/>
            </div>
          </vs-tab>
          <vs-tab label="SISEC V3">
            <div v-if="!socData">
              <h4>No se encontró el GUID</h4>
            </div>
            <div v-else>
              <SISEC/>
            </div>
          </vs-tab>
         <vs-tab label="Santander" v-if="solicidadoSantander">
           <div>
             <Santander />--> <!--v-on:validaCamposVacios="validaCamposVacios($event)"-->
           <!--</div>
          </vs-tab>-->
        </vs-tabs>
  </div>
 

          <div class="footer">COPYRIGHT 2021 Cotizador SOC, todos los derechos reservados.</div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  
</template>


<script>
import TheNavbarVerticalInmobiliaria   from '@/layouts/components/navbar/TheNavbarVerticalInmobiliaria.vue'
import Credito from '@/views/CasoInmobiliaria/CreditoInmobiliaria.vue'
import Personales from '@/views/CasoInmobiliaria/Personales/Personales.vue'
import Notas from '@/views/Caso/Notas.vue'
import Coacreditado from '@/views/Caso/Coacreditado/Coacreditado.vue'
import SISEC from '@/views/Caso/SISEC.vue'
import Santander from '@/views/Caso/Santander/Santander.vue'

export default {
  components:{
    TheNavbarVerticalInmobiliaria,
    Credito,
    Personales,
    Coacreditado,
    Notas,
    SISEC,
    Santander
  },
  data(){
		return{
      id:'',
      key:'',
      consulta:false,
      tab:1,
      userInfo:'',
      solicidadoSantander:false,
      solicitudes:[],
      camposVaciosSantanderExpress:false
    }
  },
  mounted(){
    if (this.$store.state.AppActiveUser.ApiKey) {
      this.id=this.$route.params.id
      this.getApikey()
      this.consultarCaso()
      if (this.$store.state.AppActiveUser.WidgetColorPrimario) {
        this.$vs.theme({
          primary:this.$store.state.AppActiveUser.WidgetColorPrimario
        })
      }
    }else {
      this.$router.push('/login').catch(() => {})
    }
    //localStorage.setItem('solicidadoSantander', false)}
     /*if (this.$store.state.existeProductoSantander) {
       this.solicidadoSantander=this.$store.state.existeProductoSantander
    }*/
    this.dameCaso()
    this.activaPanel()
  },
  computed: {
    socData(){
      //return this.$store.state.socData
      return JSON.parse(localStorage.getItem('socData'))
    },
    /*solicidadoSantander(){
      return this.$store.state.existeProductoSantander
      //return JSON.parse(localStorage.getItem('socData'))
    }*/
     
  },
  watch:{
    solicidadoSantander:function(){
                this.solicidadoSantander=this.$store.state.existeProductoSantander
        }
  },
  methods:{
    activaPanel(){
       this.$emit('actualiza_panel', 'HomeInmo', 'BaseClientes')
     },
    consultarCaso(){
      let self=this
      var objRequestAutentica = {
        strApiKey: this.key,
        strMetodo: 'ConsultarCaso',
        objCaso: {
            EMail: this.$store.state.AppActiveUser.EMail,
            Id: this.id
        }
      }
      this.$axios.post('/',objRequestAutentica,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            if (!response.data.Consultar) {
              this.$router.push('/');
            }else {
              this.cambiarStatus()
              this.consulta=true
            }
          }else {
            this.$router.push('/');
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    cambiarStatus(){
      let self=this
      var objRequestCambiarStatus = {
        strApiKey: this.key,
        strMetodo: 'ActualizaStatusCaso',
        objCaso: {
            Id: this.id,
            StatusCaso: 'Abierto'
        }
      }
      this.$axios.post('/',objRequestCambiarStatus,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      }
    },
    changeTab(id){
      this.tab=id
    },
    dameCaso(){
      let self=this
      var objRequestDameCaso = {
        strApiKey: this.key,
        strMetodo: 'DameCaso',
        objCaso: {
         Id: this.id
        }
      }
      this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            let cotizacion=response.data.objContenido;
            this.solicitudes=cotizacion.Solicitudes
            for (var i = 0; i < this.solicitudes.length; i++) {
              this.solicitudes[i].Resultado=this.solicitudes[i].Resultado
              this.solicitudes[i].IdCaso=this.solicitudes[i].Caso
              this.solicitudes[i].IdBanco=this.solicitudes[i].IdBanco
              this.solicitudes[i].MensualidadPromedio= this.solicitudes[i].MensualidadPromedio.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              this.solicitudes[i].Cat= this.solicitudes[i].Cat.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              if( this.solicitudes[i].IdBanco==10){
              this.$store.commit('UPDATE_EXISTE_PRODUCTO_SANTANSER',true)
              this.solicidadoSantander=true
              }            
            }
            if(this.solicitudes.length==0){
              this.$store.commit('UPDATE_EXISTE_PRODUCTO_SANTANSER',false)
              this.solicidadoSantander=false
            }
            
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    existeSolicitadoSantander(value){
      this.solicidadoSantander=value
    },
    /*validaCamposVacios(value){
      this.camposVaciosSantanderExpress=value
    }*/
  }
}
</script>

<style lang="css" scoped>
.input-group-text {
    height: 100% !important;}
.vs-tabs-position-left .vs-tabs--ul {
    background: #015694 !important;
    box-shadow: none !important;
    border: none !important;
}

/****** Nuevos estilos responsivos ****/
.caso-inmob .router-view {
  padding: 0;
}

.caso-inmob .vistaInt {
  padding-right: 0px;
  padding-top: 50px;
}

@media screen and (max-width: 896px) {
  .caso-inmob .vistaInt {
    padding-top: 2rem !important;
    padding-left: 0;
  }
}

@media screen and (max-width: 576px) {
  .caso-inmob .router-view {
    padding: 0 !important;
  }

  .caso-inmob .vistaInt {
    padding-top: 1rem !important;
  }
}

@media screen and (max-width: 414px) {
  .caso-inmob .router-view {
    padding: 0 !important;
  }

  .caso-inmob .vistaInt {
    padding-top: 0 !important;
  }
}

</style>
