<template>
<div class="">
  <ul class="nav nav-tabs" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Generales</button>
  </li>
  <li class="nav-item" role="presentation">
    <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Ingresos</button>
  </li>
</ul>
<div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
    <div class="tab-body">
     <div class="accordion" id="accordionExample">
  <div class="">
    <h2 class="accordion-header ml-2" id="headingOne">
        Personales
    </h2>
        <PersonalesGeneral/>
  </div>
</div> 
    </div>  
  </div>
<div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
  <div class="tab-body">
    <div class="accordion" id="accordionExample2">
  <div class="">
        <Ingresos/>
  </div>
</div>
    </div>  
  </div>
</div>

</div>
</template>

<script>
import PersonalesGeneral from '@/views/CasoInmobiliaria/Personales/PersonalesGeneral.vue'
//import Adeudos from '@/views/Caso/Personales/Adeudos.vue'
//import Beneficiarios from '@/views/Caso/Personales/Beneficiarios.vue'
//import CuentasBancarias from '@/views/Caso/Personales/CuentasBancarias.vue'
//import Dependientes from '@/views/Caso/Personales/Dependientes.vue'
//import Referencias from '@/views/Caso/Personales/Referencias.vue'
//import Aseguradoras from '@/views/Caso/Personales/Aseguradoras.vue'
//import ObligadoSolidario from '@/views/Caso/Personales/ObligadoSolidario.vue'
import DocumentosDigitales from '@/views/Caso/Personales/DocumentosDigitales.vue'
import Ingresos from '@/views/CasoInmobiliaria/Personales/Ingresos.vue'
import DatosAdicionales from '@/views/Caso/Personales/DatosAdicionales.vue'
import ReporteCredito from '@/views/Caso/Personales/ReporteCredito.vue'

export default {
  components: {
    PersonalesGeneral,
    //Adeudos,
    //Beneficiarios,
    //CuentasBancarias,
    //Dependientes,
    //Referencias,
    //Aseguradoras,
    //ObligadoSolidario,
    DocumentosDigitales,
    Ingresos,
    DatosAdicionales,
    ReporteCredito,
  },
  data () {
    return {
      tab:1
    }
  },
  computed: {
    isSmallerScreen () {
      return window.screen.width < 768
    },
  },
  methods:{
    changeTab(id){
      this.tab=id
    }
  }
}
</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
</style>
