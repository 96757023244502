<template lang="html">
  <div class="">
    <div class="row w-full">
      <div class="col-md-4">
        <span class="span-placeholder">Costo actual de vivienda</span>
        <div class="inputwrapper" data-required="MXM">
        <input id="demoTextBox"  class="inputDataText" placeholder="$X,XXX,XXX.XX" v-model="valores.valor" name="username" @change="updateValores()" v-on:keypress="isNumber($event)"/>
        </div>
      </div>
      <div class="col-md-4">
        <span class="span-placeholder">Restante por pagar</span>
        <div class="inputwrapper" data-required="MXM">      
        <input id="demoTextBox"  class="inputDataText" placeholder="$X,XXX,XXX.XX" v-model="valores.credito" name="credito" @change="updateValores()" v-on:keypress="isNumber($event)"/>
        </div>
        <span class="text-danger span-placeholder" v-show="validacion">Debe ser menor al valor de la propiedad</span>
      </div>
    </div>
    <div class="row w-full">
      <div class="col-md-4">
        <span class="span-placeholder">Pago mensual</span>
        <div class="inputwrapper" data-required="MXM">        
        <input id="demoTextBox"  class="inputDataText" placeholder="$X,XXX,XXX.XX" v-model="valores.mensualidad" name="username" @change="updateValores()" v-on:keypress="isNumber($event)"/>
        </div>
      </div>
      <div class="col-md-4">
        <span class="span-placeholder" for="plazos">Tiempo restante crédito</span>
        <select name="cars" id="plazos" v-model="valores.plazoRestante" class="vs-input" style="border: 1px solid rgba(0, 0, 0, 0.2);" @change="updateValores()">
          <option :value='tipo.value' v-for="tipo in plazos"  :key="">{{tipo.label}}</option>
        </select>
      </div>
    </div>
    <div class="row w-full">        
      <div class="col-md-4">
        <span class="span-placeholder">Plazos a cotizar</span>
        <v-select v-model="valores.plazosACotizar" multiple :closeOnSelect="false" :options="plazos" :dir="$vs.rtl ? 'rtl' : 'ltr'" @input="updateValores(), buscaBancos()"/>
      </div>
      <div class="col-md-4">
        <span class="span-placeholder">Esquemas de pago</span>

        <v-select v-model="valores.esquemasACotizar" multiple :closeOnSelect="false" :options="esquemas" :dir="$vs.rtl ? 'rtl' : 'ltr'" @input="updateValores(), buscaBancos()"/>
      </div>
      <!--<div class="vx-col lg:w-1/4 sm:w-full">
        <span class="span-placeholder">Gastos notariales</span>
        <select name="gastosNotariales" id="gastosNotariales" v-model="valores.gastosNotarial" class="v-select vs--single  vs-input--input" @change="updateValores()">
              <option :value='tipo.value' v-for="tipo in gastosNotariales"  :key="">{{tipo.label}}</option>
        </select>
      </div>-->
    </div>
    <input class="vs-input" hidden   v-model="otro" name="otro" @change="updateValores()" />
  </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
  components: {
    vSelect,
  },
  props: {
    otro: {
      type: Number,
      required: false
    },
   }, 
  data(){
		return{
      plazos:[
        { label: '20 Años',  value: '20' },
        { label: '19 Años',  value: '19' },
        { label: '18 Años',  value: '18' },
        { label: '17 Años',  value: '17' },
        { label: '16 Años',  value: '16' },
        { label: '15 Años',  value: '15' },
        { label: '14 Años',  value: '14' },
        { label: '13 Años',  value: '13' },
        { label: '12 Años',  value: '12' },
        { label: '11 Años',  value: '11' },
        { label: '10 Años',  value: '10' },
        { label: '9 Años',  value: '9' },
        { label: '8 Años',  value: '8' },
        { label: '7 Años',  value: '7' },
        { label: '6 Años',  value: '6' },
        { label: '5 Años',  value: '5' },
        { label: '4 Años',  value: '4' },
        { label: '3 Años',  value: '3' },
        { label: '2 Años',  value: '2' },
        { label: '1 Años',  value: '1' },
      ],
      esquemas:[
        { label: 'Pago Fijo',  value: '2' },
        { label: 'Pago creciente',  value: '1' },
      ],
      id:'',
      gastosNotariales:[{label : '1 %', value : '1'},
                        {label : '2 %', value : '2'},
                        {label : '3 %', value : '3'},
                        {label : '4 %', value : '4'},
                        {label : '5 %', value : '5'},
                        {label : '6 %', value : '6'},
                        {label : '7 %', value : '7'},
                        {label : '8 %', value : '8'},
                        {label : '9 %', value : '9'},
                        {label : '10 %', value : '10'}
      ],
      valores:{
        destino:19,
        valor:'',
        credito:'',
        mensualidad:'',
        plazoRestante:'',
        esquemasACotizar:[{ label: 'Pago Fijo',  value: '2' }],
        plazosACotizar:[{ label: '10 Años',  value: '10' },{ label: '15 Años',  value: '15' },{ label: '20 Años',  value: '20' }],
        //gastosNotarial:3
        otro:0
      }
    }
  },
  mounted(){
    this.id=this.$route.params.id
    this.getApikey()
    if(this.id)this.dameCaso()

    if(this.$route.path == '/nuevaCotizacion' && localStorage.getItem('caso')){
      this.id=localStorage.getItem('caso')
      this.dameCaso()
    }
  },
  watch: {
    mensualidad: function(newValue) {
      this.valores.mensualidad= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    valor: function(newValue) {
      this.valores.valor= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    credito: function(newValue) {
      this.valores.credito= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    otro:function(){
      if(this.otro > 0){
        this.updateValores()
      }
    }
  },
  computed:{
    validacion() {
      return this.valores.credito.replace(/,/g,"") > this.valores.valor.replace(/,/g,"")*1
    },
    valor(){
      return this.valores.valor
    },
    credito(){
      return this.valores.credito
    },
    mensualidad(){
      return this.valores.mensualidad
    }
  },
  methods:{
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    updateValores(){
      this.valores.otro=this.otro
      this.valores.destino=19
      this.$emit('updateValores',this.valores)
    },
     buscaBancos(){
      this.valores.otro=this.otro
      this.$emit('buscaBancos',this.valores)
    },
    dameCaso(){
      let self= this
      var objRequestDameCaso = {
        strApiKey: this.key,
        strMetodo: 'DameCaso',
        objCaso: {
         Id: this.id
        }
      }
      this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            let cotizacion=response.data.objContenido;
            this.valores.plazosACotizar=[]
            this.valores.esquemasACotizar=[]
            this.valores.valor=(cotizacion.ValorInmueble).toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            this.valores.credito=(cotizacion.MontoSolicitado).toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            this.valores.plazoRestante=cotizacion.PlazoRestante
            if (cotizacion.PagoMensualActual) {
              this.valores.mensualidad= (cotizacion.PagoMensualActual).toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            for (var i = 0; i < cotizacion.Plazos.length; i++) {
              this.valores.plazosACotizar.push(cotizacion.Plazos[i]+' Años')
            }
            for (var i = 0; i < cotizacion.Esquemas.length; i++) {
              if (cotizacion.Esquemas[i] == 2) {
                this.valores.esquemasACotizar.push({ label: 'Pago Fijo',  value: '2' })
              }  else if (cotizacion.Esquemas[i] == 1) {
                this.valores.esquemasACotizar.push({ label: 'Pago creciente',  value: '1' })
              }
            }
            this.updateValores()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      }
    }
  }
}
</script>

<style lang="css" scoped>
.v-select{
    width: 100%;
}
.label-select{
  margin-top: 3%;
}
</style>
