
<template>
  <div id="tabla" class="table-credit-options-body">
   <!-- <vx-card > -->   
  
            <div class="row pt-5">
                    

              <div class="card-info-productos">
                
                <div class="tr-borde">
                  <div class="col-md-2 logo-productos">
                    <img :src="'https://cotizador.socasesores.com/uploads/'+ item.Logo" :alt="item.Banco" class="recorte2">

                  </div>
                    
                  <div class="col-md-3 descripcion-productos">
                        <slot name="item-meta" >
                          <vx-tooltip :text="item.Descripcion" v-if="item.Descripcion">
                            <vs-chip type="gradient">Descripcion</vs-chip>
                          </vx-tooltip>
                          
                            <h6 class="titulo-productos">{{ item.Producto }}</h6>
                            <span class="ocultar2">Pago <span class="font-semibold cursor-pointer">{{ item.Esquema==2 ? 'Fijo' : 'Creciente' }}</span> durante <span class="font-semibold cursor-pointer">{{ item.Plazo/12 }} años</span></span>
                            <span class="ocultar mensualidad">
                              <span >Mensualidad: &nbsp;</span>
                              <span>${{ numberFormat(item.MensualidadInicial) }}</span>
                            </span>  
                        </slot>                        
                  </div>
                  <div class="col-md-1 info-productos">
                    <!-- Button trigger modal -->
                    
                    <button @click="activePrompt=true;popupActive=false;"  class="btn btn-infor" >
                    <svg class="info" viewBox="0 0 32 32" width="32" height="32">
                      <g >
                      <g transform="translate(0)">
                        <g>
                          <path id="Vector" d="M9.625,27.5h8.25c6.875,0,9.625-2.75,9.625-9.625V9.625C27.5,2.75,24.75,0,17.875,0H9.625C2.75,0,0,2.75,0,9.625v8.25C0,24.75,2.75,27.5,9.625,27.5Z" transform="translate(2.75 2.75)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                          <path id="Vector-2" data-name="Vector" d="M0,0H33V33H0Z" fill="none" opacity="0"/>
                        </g>
                      </g>
                        <g transform="translate(16.5 22.379) rotate(180)">
                          <path id="Vector-3" data-name="Vector" d="M0,0V7.219"  stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                          <path class="i" id="Vector-4" data-name="Vector" d="M0,0V.137" transform="translate(0 11.619)"   stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                        </g>
                      </g>
                    </svg>
                    </button>
                    <vs-prompt title="Detalles" class="export-options prompt-modal modal-inmob-detalle" :active.sync="activePrompt">
                      
                        <slot name="item-meta">
                          <tr>
                            <td colspan="2">
                              <img :src="'https://cotizador.socasesores.com/uploads/'+ item.Logo" :alt="item.Banco" class="recorte">

                              <vx-tooltip :text="item.Descripcion" v-if="item.Descripcion">
                                
                                <vs-chip type="gradient">Descripcion</vs-chip>
                              </vx-tooltip>

                                <h6 class=" titulo">{{ item.Producto }}</h6>
                                <span style="color:#00368C;" >Pago <span class="font-semibold cursor-pointer">{{ item.Esquema==2 ? 'Fijo' : 'Creciente' }}</span> durante <span class="font-semibold cursor-pointer">{{ item.Plazo/12 }} años</span></span>

                            </td>
                          </tr>
                          

                            <slot name="mejora"></slot>

                            <tr>
                              <td class="td-modal">Mensualidad promedio:</td>
                              <td>${{ numberFormat(item.MensualidadPromedio) }}</td>
                            </tr>
                            <tr>
                              <td class="td-modal">Tasa:</td>
                              <td>{{ numberFormat(item.Tasa) }}%</td>
                            </tr>
                            <tr>
                              <td class="td-modal">Cat:</td>
                              <td>{{ numberFormat(item.Cat) }}%</td>
                            </tr>
                          </slot>

                            <tr>
                              <td class="td-modal">Ingreso mínimo:</td>
                              <td>${{ numberFormat(item.IngresoMinimo) }}</td>
                            </tr>
                            <tr>
                              <td class="td-modal">Pago Total:</td>
                              <td>${{ numberFormat(item.PagoTotal) }}</td>
                            </tr>
                            <tr>
                              <td class="td-modal">Aforo:</td>
                              <td>{{ numberFormat(item.Aforo) }}%</td>
                            </tr>
                            <tr>
                              <td class="td-modal">Mensualidad Inicial:</td>
                              <td>${{ numberFormat(item.MensualidadInicialPromedio) }}</td>
                            </tr>
                              <!--<slot name="cofinavit"></slot>-->
                            <tr>
                                <slot name="gastos"></slot>
                            </tr>  
                            <tr>
                              <td class="td-modal">Enganche</td>
                              <td> ${{ numberFormat(item.Enganche) }}</td>
                            </tr>

                    </vs-prompt>                 
                  </div>
                  <div class="col-md-2 acciones">
                    
                    <!--<h5 class="text-center" v-if="filtroId!==5">{{filtroName}}</h5>-->
                            <h5 class="font-bold text-center" v-if="filtroId==1">$ {{ numberFormat(item.MensualidadInicialPromedio) }}</h5>
                            <h5 class="font-bold text-center" v-else-if="filtroId==2"> {{ numberFormat(item.Cat) }} %</h5>
                            <h5 class="font-bold text-center" v-else-if="filtroId==3"> {{ numberFormat(item.Tasa) }} %</h5>
                            <h5 class="font-bold text-center" v-else-if="filtroId==4">$ {{ numberFormat(item.PagoTotal) }}</h5>
                            <h5 class="font-bold text-center" v-else-if="filtroId==6 ">$ {{ numberFormat(item.IngresoMinimo) }}</h5>
                            <h5 class="font-bold text-center" v-else-if="filtroId==7 && item.BancoId!=21">$ {{ numberFormat(item.AhorroTotal) }}</h5>
                            <h5 class="font-bold text-center" v-else-if="filtroId==8 && item.BancoId!=21">$ {{ numberFormat(item.MejoraMensualidad) }}</h5>
                            <h5 class="font-bold text-center" v-else-if="filtroId==5 && item.BancoId!=21"> {{ item.vctPromociones.length > 0 ? item.vctPromociones.length : 'Sin ' }} promociones</h5>
                    
                  </div>
                  <div class="col-md-2 acciones-productos">
                    <div class="d-flex justify-content-center ">
                      <div class="icono-tabla">   
                              <button class="favoritos" type="line" @click="tablaAmortizacionBancos(item)">
                                <svg class="note" viewBox="0 0 32 32" width="32" height="32">
                                  <g id="note">
                                    <path id="Vector" d="M0,0V4.188" transform="translate(11.167 2.792)"  stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                    <path id="Vector-2" data-name="Vector" d="M0,0V4.188" transform="translate(22.333 2.792)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                    <path id="Vector-3" data-name="Vector" d="M0,0H11.167" transform="translate(9.771 18.146)"  stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                    <path id="Vector-4" data-name="Vector" d="M0,0H6.979" transform="translate(9.771 23.729)"  stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                    <path id="Vector-5" data-name="Vector" d="M18.146,0c4.648.251,6.979,2.024,6.979,8.584v8.626c0,5.751-1.4,8.626-8.375,8.626H8.375C1.4,25.837,0,22.961,0,17.211V8.584C0,2.024,2.331.265,6.979,0Z" transform="translate(4.188 4.885)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                  </g>
                                </svg>  
                              </button>
                              <span class="ocultar leyenda-tabla"><br>Tabla de pagos</span>
                      </div> 
                      <div class="icono-favoritos">   
                        <slot name="action-buttons-2" />
                        <div class="ocultar leyenda-favoritos"><br>Favoritos</div>
                      </div> 
                    </div>                         
                  </div>    
                  <!--
                  <td class="col-md-1">
                    <vx-tooltip color="primary" :text="'PROMOCIÓN: '+promos" v-for="promos in item.vctPromociones" :key="promos">
                                <svg class="star">
                                  <path id="Vector" d="M15.639,1.91,17.985,6.6A2.9,2.9,0,0,0,19.879,8l4.253.707c2.72.453,3.36,2.427,1.4,4.373L22.225,16.39a2.9,2.9,0,0,0-.693,2.413l.947,4.093c.747,3.24-.973,4.493-3.84,2.8l-3.987-2.36a2.882,2.882,0,0,0-2.64,0L8.025,25.7c-2.853,1.693-4.587.427-3.84-2.8L5.132,18.8a2.9,2.9,0,0,0-.693-2.413L1.132,13.083c-1.947-1.947-1.32-3.92,1.4-4.373L6.785,8a2.9,2.9,0,0,0,1.88-1.4L11.012,1.91C12.292-.637,14.372-.637,15.639,1.91Z" transform="translate(2.668 2.77)"  stroke="#e2b41a" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                </svg>
                    </vx-tooltip>
                  </td>-->  
                  <div class="col-md-1 promocion-productos">
                    <div class="promocion-productos-lista">
                      <vx-tooltip color="primary" :text="'PROMOCIÓN: '+promos" v-for="promos in item.vctPromociones" :key="promos">
                        <svg class="star" viewBox="0 0 32 32" width="32" height="32">
                          <path id="Vector" d="M15.639,1.91,17.985,6.6A2.9,2.9,0,0,0,19.879,8l4.253.707c2.72.453,3.36,2.427,1.4,4.373L22.225,16.39a2.9,2.9,0,0,0-.693,2.413l.947,4.093c.747,3.24-.973,4.493-3.84,2.8l-3.987-2.36a2.882,2.882,0,0,0-2.64,0L8.025,25.7c-2.853,1.693-4.587.427-3.84-2.8L5.132,18.8a2.9,2.9,0,0,0-.693-2.413L1.132,13.083c-1.947-1.947-1.32-3.92,1.4-4.373L6.785,8a2.9,2.9,0,0,0,1.88-1.4L11.012,1.91C12.292-.637,14.372-.637,15.639,1.91Z" transform="translate(2.668 2.77)"  stroke="#e2b41a" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        </svg>

                        <!-- Mostrar leyenda de promocion en mobile -->
                        <div class="ocultar leyenda-promocion"><strong>PROMOCIÓN:</strong><br>{{ promos }}</div>
                      </vx-tooltip>
                    </div>
                  </div>  
                  <div class="col-md-1 favoritos-productos">
                    <div class="d-flex justify-content-center" >
                        <slot name="action-buttons" /> 
                    </div>
                  </div>
                </div>


                <!-- IMG COL -->


                <!-- ITEM NAME & DESC COL -->
                <div class="vx-col sm:w-1/2">
                    <div class="p-4 pt-6">

                        <vs-popup fullscreen title="Tabla de amortización" :active.sync="popupActiveExc">
                          <vs-prompt title="Exportar a Excel" class="export-options" cancel-text="Cancelar" @cancle="clearFields" @accept="exportToExcel" accept-text="Exportar" @close="clearFields" :active.sync="activePromptExc">
                              <input v-model="fileName" placeholder="Nombre del archivo" class="w-full" />
                              <v-select v-model="selectedFormat" :options="formats" class="my-4" />
                              <div class="flex">
                                <span class="mr-4">Tamaño de la celda automático:</span>
                                <vs-switch v-model="cellAutoWidth">Tamaño automático</vs-switch>
                              </div>
                          </vs-prompt>
                          <vs-table :data="itemsTable" max-items="12"
                            pagination
                            noDataText="No hay datos para mostrar" class="tabla-inmob-amortizacion mr-0">
                            <template slot="header">
                              <!--<vs-button @click="activePromptExc=true;popupActiveExc=false;" class="export">Exportar a tabla de Excel</vs-button>
                              
                              <vs-button @click="sendEmail(item)" class="export" >Enviar por correo</vs-button> -->
                            </template>
                            <template slot="thead">
                              <vs-th>
                                Mes
                              </vs-th>
                              <vs-th>
                                Capital
                              </vs-th>
                              <vs-th>
                                Tasa
                              </vs-th>
                              <vs-th>
                                Intereses
                              </vs-th>
                              <vs-th>
                                Amortización
                              </vs-th>
                              <vs-th>
                                Mensualidad
                              </vs-th>
                              <vs-th>
                                Gastos
                              </vs-th>
                              <vs-th>
                                Pago Mensual
                              </vs-th>
                            </template>

                            <template slot-scope="{data}">
                              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                                <vs-td :data="data[indextr].Mes" >
                                  {{data[indextr].Mes}}
                                </vs-td>

                                <vs-td :data="data[indextr].Capital">
                                  $ {{numberFormat(data[indextr].Capital)}}
                                </vs-td>

                                <vs-td :data="data[indextr].Tasa">
                                  {{numberFormat(data[indextr].Tasa)}}%
                                </vs-td>

                                <vs-td :data="data[indextr].Interes">
                                  $ {{numberFormat(data[indextr].Interes)}}
                                </vs-td>
                                <vs-td :data="data[indextr].Amortizacion">
                                  ${{numberFormat(data[indextr].Amortizacion)}}
                                </vs-td>
                                <vs-td :data="data[indextr].Mensualidad">
                                  $ {{numberFormat(data[indextr].Mensualidad)}}
                                </vs-td>
                                <vs-td :data="data[indextr].GastosDiferidos">
                                  $ {{numberFormat(data[indextr].GastosDiferidos)}}
                                </vs-td>
                                <vs-td :data="data[indextr].PagoMensual">
                                  $ {{numberFormat(data[indextr].PagoMensual)}}
                                </vs-td>
                              </vs-tr>
                            </template>
                          </vs-table>
                          <div class="mt-8">
                            <h5>Totales</h5>
                            <vs-table :data="itemsTable" class="tabla-inmob-totales mr-0">
                              <template slot="thead">
                                <vs-th>
                                  Meses Totales
                                </vs-th>
                                <vs-th>
                                  Monto de crédito
                                </vs-th>
                                <vs-th>
                                  Tasa
                                </vs-th>
                                <vs-th>
                                  Suma intereses
                                </vs-th>
                                <vs-th>
                                  Suma amortización
                                </vs-th>
                                <vs-th>
                                  Suma mensualidad
                                </vs-th>
                                <vs-th>
                                  Gastos totales
                                </vs-th>
                                <vs-th v-if="item.BancoId!=21">
                                  Pago Mensual total
                                </vs-th>
                                <vs-th v-if="item.BancoId==21">
                                  Pago total
                                </vs-th>
                              </template>

                              <template>
                                <vs-tr>
                                  <vs-td>
                                    {{mesesTotales}}
                                  </vs-td>
                                  <vs-td>
                                    $ {{numberFormat(amortizacionTotal)}}
                                  </vs-td>
                                  <vs-td>
                                    {{numberFormat(tasa)}}%
                                  </vs-td>
                                  <vs-td>
                                    $ {{numberFormat(interesesTotales)}}
                                  </vs-td>
                                  <vs-td>
                                    $ {{numberFormat(amortizacionTotal)}}
                                  </vs-td>
                                  <vs-td>
                                    $ {{numberFormat(mensualidadTotal)}}
                                  </vs-td>
                                  <vs-td>
                                    $ {{numberFormat(gastosTotales)}}
                                  </vs-td>
                                  <vs-td>
                                    $ {{numberFormat(pagoMensualTotal)}}
                                  </vs-td>
                                </vs-tr>
                              </template>
                            </vs-table>
                          </div>
                        </vs-popup>

                    </div>
                </div>

                <!-- PURCHASE COL -->
                <!--
                <div class="vx-col sm:w-1/4 w-full flex items-center sm:border border-0 border-solid d-theme-border-grey-light border-r-0 border-t-0 border-b-0">
                    <div class="p-4 flex flex-col w-full">
                      
                      <vs-row v-if="user && !ingreso" vs-w="12"  class="mb-2">
                          <p class="span-placeholder text-danger">Es necesario capturar ingresos para visualizar el cumplimiento de las condiciones</p>
                      </vs-row>
                      <div class="" v-if="user && ingreso">
                        <vs-row vs-w="12" vs-justify="space-between">
                          <p class="span-placeholder">Cumplimiento {{item.Perfil}}%</p>
                          <vx-tooltip color="primary" :text="'Condiciones incumplidas: '+incumplidas" v-if="item.Perfil < 100">
                            <feather-icon icon="InfoIcon"/>
                          </vx-tooltip>
                        </vs-row>
                        <vs-progress :percent="item.Perfil" color="primary"></vs-progress>
                      </div>
              
                        <div class="my-6">
                            <h5 class="text-center" v-if="filtroId!==5">{{filtroName}}</h5>
                            <h5 class="font-bold text-center" v-if="filtroId==1">$ {{ numberFormat(item.MensualidadInicial) }}</h5>
                            <h5 class="font-bold text-center" v-else-if="filtroId==2"> {{ numberFormat(item.Cat) }} %</h5>
                            <h5 class="font-bold text-center" v-else-if="filtroId==3"> {{ numberFormat(item.Tasa) }} %</h5>
                            <h5 class="font-bold text-center" v-else-if="filtroId==4">$ {{ numberFormat(item.PagoTotal) }}</h5>
                            <h5 class="font-bold text-center" v-else-if="filtroId==6">$ {{ numberFormat(item.IngresoMinimo) }}</h5>
                            <h5 class="font-bold text-center" v-else-if="filtroId==7">$ {{ numberFormat(item.AhorroTotal) }}</h5>
                            <h5 class="font-bold text-center" v-else-if="filtroId==8">$ {{ numberFormat(item.MejoraMensualidad) }}</h5>
                            <h5 class="font-bold text-center" v-else-if="filtroId==5"> {{ item.vctPromociones.length > 0 ? item.vctPromociones.length : 'Sin ' }} promociones</h5>
                        </div>

                    </div>
                    
                </div>-->
              </div>
            </div>
    <!--</vx-card>-->
  </div>
</template>

<script>
import vSelect from 'vue-select'
import axios from 'axios';
export default{
  components: {
    vSelect
  },
  props: {
     idCaso: {
      type: Number,
      required: false
    },
    item: {
      type: Object,
      required: true
    },
    user: {
      type: String,
      required: false
    },
    filtroName:{
      type: String,
      required: false
    },
    filtroId:{
      type: Number,
      required: false
    },
    ingreso: {
      type: Object,
      required: false
    },
  },
  data(){
		return{
      itemId:'',
      solicitante:'',
      fileName: '',
      formats:['xlsx', 'csv', 'txt'],
      cellAutoWidth: true,
      selectedFormat: 'xlsx',
      headerTitle: ['Mes', 'Capital', 'Tasa', 'Interes', 'Amortizacion','Mensualidad','Gastos','Pago'],
      headerVal: ['Mes', 'Capital', 'Tasa', 'Interes', 'Amortizacion','Mensualidad','GastosDiferidos','PagoMensual'],
      popupActive: false,
      popupActiveExc: false,
      activePrompt: false,
      activePromptExc: false,
      itemsTable:[],
      interesesTotales:0,
      mensualidadTotal:0,
      mesesTotales:0,
      montoSolicitado:0,
      amortizacionTotal:0,
      gastosTotales:0,
      pagoMensualTotal:0,
      tasa:0,
    }
  },
  mounted(){
    this.id=this.$route.params.id
    //this.idCaso=this.$route.params.id
    this.getApikey()
    if(this.id){
      //this.idCaso=this.id
      this.dameCaso()
    }
  },
  computed:{
    socData(){
      //return this.$store.state.socData
      return JSON.parse(localStorage.getItem('socData'))
    },
    incumplidas:function(){
      if (this.item.vctIncumplidas.length) {
        let text=''
        for (var i = 0; i < this.item.vctIncumplidas.length; i++) {
          let j=i+1
          text=text + ' '+ j +':'+this.item.vctIncumplidas[i]
        }//console.log(text)
        return text
      }
    }
  },
  methods: {
    numberFormat(num){
      return num.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    tablaAmortizacionBancos(item){
       if(item.BancoId==33){
        this.tablaAmorKredi(item) 

      }else{
        this.tablaAmortizacion(item) 
      }

    },
    sendEmail(variant){
      let self=this
      var objEmail = {
        to: this.solicitante,
        case: this.id,
        token:this.key,
        variant:variant.Id,
        variant_name:variant.Producto,
        bank:variant.Banco,
        amortizacionIon: variant.JsonAmortizacion
      }
      axios.post('https://cotizador.socasesores.com/emailComparaleProd/Simulation.php',objEmail,{headers : {'Content-Type':'application/json; charset=UTF-8'}})
      .then(
        //response => {
          this.$vs.notify({
            title:'Se envió correctamente el correo electrónico a',
            text:this.solicitante,
            position:'top-right'
          })
       // }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameCaso(){
      let self=this
      var objRequestDameCaso = {
        strApiKey: this.key,
        strMetodo: 'DameCaso',
        objCaso: {
         Id: this.id
        }
      }
      this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            let cotizacion=response.data.objContenido;
            this.solicitante=cotizacion.Solicitante
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      }
    },
    exportToExcel () {
      import('@/vendor/Export2Excel').then(excel => {
      let items=[]
        for(let i=0;i<this.itemsTable.length;i++){
          let itemTabla=[]
          itemTabla.Amortizacion='$ '+this.numberFormat(this.itemsTable[i].Amortizacion)
          itemTabla.Capital='$ '+this.numberFormat(this.itemsTable[i].Capital)
          itemTabla.GastosDiferidos='$ '+this.numberFormat(this.itemsTable[i].GastosDiferidos)
          itemTabla.Fecha=this.itemsTable[i].Fecha
          itemTabla.Interes='$ '+this.numberFormat(this.itemsTable[i].Interes)
          itemTabla.Mensualidad='$ '+this.numberFormat(this.itemsTable[i].Mensualidad)
          itemTabla.Mes=this.itemsTable[i].Mes
          itemTabla.PagoMensual='$ '+this.numberFormat(this.itemsTable[i].PagoMensual)
          itemTabla.Tasa=this.numberFormat(this.itemsTable[i].Tasa)+'%'
          
          items.push(itemTabla)
        }

        const list = items
        const data = this.formatJson(this.headerVal, list)
        excel.export_json_to_excel({
          header: this.headerTitle,
          data,
          filename: this.fileName,
          autoWidth: this.cellAutoWidth,
          bookType: this.selectedFormat
        })
        this.clearFields()
      })
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        return v[j]
      }))
    },
    clearFields () {
      this.filename = ''
      this.cellAutoWidth = true
      this.selectedFormat = 'xlsx'
    },
    DescargaPdfIon(urlIon){
    let self=this
    
      var objPdf = {
        url: urlIon

      }
      axios.post('https://cotizador.socasesores.com/DescargaPdfIon.php',objPdf,{headers : {'Content-Type':'application/json; charset=UTF-8'}})
      .then(
        response => {
          
          location.href= 'https://cotizador.socasesores.com/uploads/AmortizacionIon/AmortizacionIon.php?objUrl='+JSON.stringify(urlIon)



        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    tablaAmorKredi(item){
      
      let self=this
      if(this.id>0){
        this.idCaso=this.id
      }

       this.$vs.loading({
          container: '#tabla',
          scale: 0.6
        })

        var product_application = {
				idTablaAmortizacion: item.JsonAmortizacion,
        plazoKredi: item.Plazo
			
			}
      this.$axios
				.post('https://cotizador.socasesores.com/ApiION/MethodoKredAmortizacion.php', product_application, { headers: { 'Content-Type': 'application/json; charset=UTF-8' } })
				.then((response) => {
           
					//this.itemsTable = JSON.parse(response.data.objRespuesta)   


           this.interesesTotales=0
            this.mensualidadTotal=0
            this.amortizacionTotal=0
            this.gastosTotales=0
            this.pagoMensualTotal=0
            this.mesesTotales=0
            this.montoSolicitado=0
            this.tasa=0
            this.itemsTable= response.data.objContenido
            this.popupActive, this.popupActiveExc = true
            for (var i = 0; i < this.itemsTable.length; i++) {
              this.interesesTotales =this.interesesTotales +this.itemsTable[i].Interes
              this.mensualidadTotal =this.mensualidadTotal +this.itemsTable[i].Mensualidad
              this.amortizacionTotal =this.amortizacionTotal +this.itemsTable[i].Amortizacion
              this.gastosTotales =this.gastosTotales +this.itemsTable[i].GastosDiferidos
              this.pagoMensualTotal =this.pagoMensualTotal +this.itemsTable[i].PagoMensual
              this.tasa=this.itemsTable[i].Tasa
              this.mesesTotales=this.itemsTable[i].Mes
              this.montoSolicitado=this.itemsTable[i].Capital
            }
          
         

        this.$vs.loading.close('#tabla > .con-vs-loading')
		
					
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un errros en api KREDI',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})


    },
    tablaAmortizacion(item){
      let self=this
      if(this.id>0){
        this.idCaso=this.id
      }
      let TablaPagosIon= ''
      let AmortizacionPdf=''
      let AmortizacionJson=''
      if(item.BancoId==21){
        TablaPagosIon=true;
        AmortizacionPdf= item.PdfAmortizacion
        AmortizacionJson= item.JsonAmortizacion
      }

      this.$vs.loading({
          container: '#tabla',
          scale: 0.6
        })
      let objRequestDameTablaPagos = {
        strApiKey: this.key,
        strMetodo: 'DameTablaPagos',
        objResultado: {
          Id:item.Id,
          caso:this.id,
          caso:this.idCaso,
          IonAmortizacion:TablaPagosIon,
          pdfIon:AmortizacionPdf,
          jsonIon:AmortizacionJson,
          plazo: item.Plazo,
          tasa: item.Tasa
        }
      }
      this.$axios.post('/',objRequestDameTablaPagos,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
      if(response.data.intEstatus != 0 ){
            this.$vs.notify({
              title:'Ocurrio un error en DameTablaPagos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }else {

            if(item.BancoId==21){
               //this.DescargaPdfIon(response.data.objContenido)
               //this.$vs.loading.close('#tabla > .con-vs-loading')

            this.interesesTotales=0
            this.mensualidadTotal=0
            this.amortizacionTotal=0
            this.gastosTotales=0
            this.pagoMensualTotal=0
            this.mesesTotales=0
            this.montoSolicitado=0
            this.tasa=0
            this.itemsTable= response.data.objContenido
            this.popupActive, this.popupActiveExc = true
            for (var i = 0; i < this.itemsTable.length; i++) {
              this.interesesTotales =this.interesesTotales +this.itemsTable[i].Interes
              this.mensualidadTotal =this.mensualidadTotal +this.itemsTable[i].Mensualidad
              this.amortizacionTotal =this.amortizacionTotal +this.itemsTable[i].Amortizacion
              this.gastosTotales =this.gastosTotales +this.itemsTable[i].GastosDiferidos
              this.pagoMensualTotal =this.pagoMensualTotal +this.itemsTable[i].PagoMensual
              this.tasa=this.itemsTable[i].Tasa
              this.mesesTotales=this.itemsTable[i].Mes
              this.montoSolicitado=this.itemsTable[i].Capital
            }
            this.$vs.loading.close('#tabla > .con-vs-loading')

            }else{

            this.interesesTotales=0
            this.mensualidadTotal=0
            this.amortizacionTotal=0
            this.gastosTotales=0
            this.pagoMensualTotal=0
            this.mesesTotales=0
            this.montoSolicitado=0
            this.tasa=0
            this.itemsTable= response.data.objContenido
            this.popupActive, this.popupActiveExc = true
            for (var i = 0; i < this.itemsTable.length; i++) {
              this.interesesTotales =this.interesesTotales +this.itemsTable[i].Interes
              this.mensualidadTotal =this.mensualidadTotal +this.itemsTable[i].Mensualidad
              this.amortizacionTotal =this.amortizacionTotal +this.itemsTable[i].Amortizacion
              this.gastosTotales =this.gastosTotales +this.itemsTable[i].GastosDiferidos
              this.pagoMensualTotal =this.pagoMensualTotal +this.itemsTable[i].PagoMensual
              this.tasa=this.itemsTable[i].Tasa
              this.mesesTotales=this.itemsTable[i].Mes
              this.montoSolicitado=this.itemsTable[i].Capital
            }
            this.$vs.loading.close('#tabla > .con-vs-loading')

            }



          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    
  }
}
</script>

<style lang="scss" scoped>
.vs-dialog footer{display: none  !important;;}
.vx-card{
  margin-bottom: 1%;
}
.list-view-item {
    .item-name, .item-description {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .item-name {
        -webkit-line-clamp: 2;
    }

    .item-description {
        -webkit-line-clamp: 5;
    }

    .grid-view-img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        transition: .35s;
    }

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0px 4px 25px 0px rgba(0,0,0,.25);

        .grid-view-img{
            opacity: 0.9;
        }
    }
}
.export{
    margin-bottom: 3%;
    float: right;
}
  .favoritos{
    border: none !important;
    background: transparent !important;
  }
  .w2-full{
    padding-top: 20px !important;
    width: 100%;
  }
  .recorte{
    width: 170px;
    height: 45px;}
    
  .recorte2{
    width: 150px;
    height: 45px;}

  img{object-fit: cover !important;}

  @media screen and (max-width:915px) {
    .w2-full{
    padding-top: 0px !important;
    width: auto;
  }
    
  }
</style>
