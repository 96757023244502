<template lang="html">
  <div class="">
    <div class="row w-full">
      <div class="col-md-4">
        <span class="span-placeholder">Valor de vivienda</span>
        <div class="inputwrapper" data-required="MXM">
        <input  id="demoTextBox"  class="inputDataText" placeholder="$X,XXX,XXX.XX" v-validate="'max:11', 'min:6'"  v-model="valores.valor" name="vv" @change="updateValores(); formatea(); calculaPrcentajeCredito()" v-on:keyup="validavalor()" v-on:keypress="isNumber($event)"/>
        <span class="text-danger span-placeholder" v-show="errors.has('vv')">Mínimo son 6 digitos</span>
        </div>
      </div>
      <div class="col-md-4">
        <span class="span-placeholder">Monto de crédito</span>
        <div class="alinear" >
        <input id="demoTextBox"  class="inputDataText" placeholder="$X,XXX,XXX.XX" v-validate="'max:13'" label="Monto de crédito" v-model="valores.credito" name="credito" @change="updateValores(); formateaCredito(); calculaPrcentajeCredito() " v-on:keyup="validaCredito()" v-on:keypress="isNumber($event)"/>
        <div class="porcentaje">{{valores.porcentajeCredito}}</div>
        <!--<span class="text-danger span-placeholder" v-show="errors.has('credito')">Máximo son 10 digitos</span>-->
        </div>          
        <span class="text-danger span-placeholder" v-show="validacion">Debe ser máximo el 95% del valor de la propiedad. </span>




      </div>
    </div>


    <div class="row w-full">
      <div class="col-md-4">
        <span class="span-placeholder">Plazos a cotizar</span>
        <v-select v-model="valores.plazosACotizar" multiple :closeOnSelect="false" :options="plazos" :dir="$vs.rtl ? 'rtl' : 'ltr'" @input="updateValores(), buscaBancos()"  />
      </div>

      <div class="col-md-4">
        <span class="span-placeholder">Esquemas de pago</span>
        <v-select v-model="valores.esquemasACotizar" multiple :closeOnSelect="false" :options="esquemas" :dir="$vs.rtl ? 'rtl' : 'ltr'" @input="updateValores(), buscaBancos()"/>
      </div>
      <!--<div class="vx-col lg:w-1/4 sm:w-full">
          <span class="span-placeholder" for="gastosNotariales">Gastos notariales</span>
            <select name="gastosNotariales" id="gastosNotariales" v-model="valores.gastosNotarial" class="v-select vs--single  vs-input--input" @change="updateValores()">
              <option :value='tipo.value' v-for="tipo in gastosNotariales"  :key="">{{tipo.label}}</option>
            </select>
      </div>-->
    </div>
    <input class="vs-input" hidden   v-model="otro" name="otro" @change="updateValores()" />
  </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
  components: {
    vSelect,
  },
  props: {
    otro: {
      type: Number,
      required: false
    },
   },
  data(){
		return{
      plazos:[
        { label: '20 Años',  value: '20' },
        { label: '15 Años',  value: '15' },
        { label: '10 Años',  value: '10' },
        { label: '5 Años',  value: '5' },
        { label: '19 Años',  value: '19' },
        { label: '18 Años',  value: '18' },
        { label: '17 Años',  value: '17' },
        { label: '16 Años',  value: '16' },
        { label: '14 Años',  value: '14' },
        { label: '13 Años',  value: '13' },
        { label: '12 Años',  value: '12' },
        { label: '11 Años',  value: '11' },
        { label: '9 Años',  value: '9' },
        { label: '8 Años',  value: '8' },
        { label: '7 Años',  value: '7' },
        { label: '6 Años',  value: '6' },
        { label: '4 Años',  value: '4' },
        { label: '3 Años',  value: '3' },
        { label: '2 Años',  value: '2' },
        { label: '1 Años',  value: '1' },
      ],
      esquemas:[
        { label: 'Pago Fijo',  value: '2' },
        { label: 'Pago creciente',  value: '1' },
      ],
      programas:[],
      id:'',
      valores:{
        destino:1,
        valor:'1,000,000',
        credito:'700,000',
        esquemasACotizar:[{ label: 'Pago Fijo',  value: '2' }],
        plazosACotizar:[{ label: '20 Años',  value: '20' }],
        bancos:[],
        //gastosNotarial:3
         otro:0,
        porcentajeCredito:70.00
      },
      gastosNotariales:[{label : '1 %', value : '1'},
                        {label : '2 %', value : '2'},
                        {label : '3 %', value : '3'},
                        {label : '4 %', value : '4'},
                        {label : '5 %', value : '5'},
                        {label : '6 %', value : '6'},
                        {label : '7 %', value : '7'},
                        {label : '8 %', value : '8'},
                        {label : '9 %', value : '9'},
                        {label : '10 %', value : '10'}
      ]
    }
  },
  mounted(){
    this.id=this.$route.params.id
    this.getApikey()
    if(this.id){this.dameCaso()
    }
    if(this.$route.path == '/nuevaCotizacion' && localStorage.getItem('caso')){
      this.id=localStorage.getItem('caso')
      this.dameCaso()
    }
   // this.updateValores()
  },
  watch: {
    /*valor: function(newValue) {
      this.valores.valor= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    credito: function(newValue) {
      this.valores.credito= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },*/
    otro:function(){
      if(this.otro > 0){
        this.updateValores()
      }
    }
  },
  computed:{
    validacion() {
      return this.valores.credito.toString().replace(/,/g,"") > this.valores.valor.toString().replace(/,/g,"") * 0.95
    },
    valor(){
      return this.valores.valor
    },
    credito(){
      return this.valores.credito
    }
  },
  methods:{
    calculaPrcentajeCredito(){
      this.valores.porcentajeCredito= (this.valores.credito.toString().replace(/,/g,"") / this.valores.valor.toString().replace(/,/g,"")) * 100
      this.valores.porcentajeCredito=this.valores.porcentajeCredito.toFixed(2)+'%'
    },
    validavalor() {
      this.valores.valor=this.valores.valor.replace(/,/g,"")
      const regex = /^(\d{1,9})(\.\d{0,2})?$/  
      const matches = regex.test(this.valores.valor);
      if(matches===true){
      this.valores.valor= this.valores.valor
      }else{
        this.valores.valor=this.valores.valor.replace(/.$/, '')
      }
    },
    formatea(){
      if(this.valores.valor==''){
        this.valores.valor=0
      }
      if(this.valores.valor.length < 6 ){
        this.valores.valor = 100000
      }


      this.valores.valor = parseFloat(this.valores.valor).toFixed(2)
      const exp = /(\d)(?=(\d{3})+(?!\d))/g;  
      const rep = '$1,';
      this.valores.valor= this.valores.valor.toString().replace(exp,rep);
    },
    validaCredito() {
      this.valores.credito=this.valores.credito.replace(/,/g,"")
      const regex = /^(\d{1,8})(\.\d{0,2})?$/  
      const matches = regex.test(this.valores.credito);
      if(matches===true){
      this.valores.credito= this.valores.credito
      }else{
        this.valores.credito=this.valores.credito.replace(/.$/, '')
      }
    },
    formateaCredito(){

      if(this.valores.credito=='' || this.valores.credito== 0){
        this.valores.credito=700000
      }
      
      this.valores.credito = parseFloat(this.valores.credito).toFixed(2)
      const exp = /(\d)(?=(\d{3})+(?!\d))/g;  
      const rep = '$1,';
      this.valores.credito= this.valores.credito.toString().replace(exp,rep);
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    updateValores(){  
      this.valores.otro=this.otro
      this.$emit('updateValores',this.valores)
    },
    buscaBancos(){
      this.valores.otro=this.otro
      this.$emit('buscaBancos',this.valores)
    },
    dameCaso(){
      let self= this
      var objRequestDameCaso = {
        strApiKey: this.key,
        strMetodo: 'DameCaso',
        objCaso: {
         Id: this.id
        }
      }
      this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            let cotizacion=response.data.objContenido;
            // this.valores.destino=cotizacion.Destino
            this.valores.plazosACotizar=[]
            this.valores.esquemasACotizar=[]
            this.valores.valor=(cotizacion.ValorInmueble).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")//.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            this.valores.credito=(cotizacion.MontoSolicitado).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")//.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            for (var i = 0; i < cotizacion.Plazos.length; i++) {
              this.valores.plazosACotizar.push(cotizacion.Plazos[i]+' Años')
            }
            for (var i = 0; i < cotizacion.Esquemas.length; i++) {
              if (cotizacion.Esquemas[i] == 2) {
                this.valores.esquemasACotizar.push({ label: 'Pago Fijo',  value: '2' })
              }  else if (cotizacion.Esquemas[i] == 1) {
                this.valores.esquemasACotizar.push({ label: 'Pago creciente',  value: '1' })
              }
            }
            this.calculaPrcentajeCredito()
            this.updateValores()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      }
    },
  }
}
</script>

<style lang="css" scoped>
.select-custom{
  margin-top:0.5% !important;
}
</style>
